import { UUID } from '../app/basicModels';
import { BasicFormPayload, MethodologyDeep, PhenomenonInfo } from './models';
import { SimpleCharacteristicsIndicatorsProps } from '../../components/characteristicsIndicators/models/characteristicsIndicators.model';
import { Characteristic, IndependentVariable, Indicator, Methodology, CustomVariableType } from '../models';

const actions = {
  LOAD_METHODOLOGY_DEEP: '[Methodology Deep] Load Methodology Deep',
  LOAD_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Load Methodology Deep Success',
  LOAD_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Load Methodology Deep Failure',
  METHODOLOGY_DEEP_REDIRECTED: '[Methodology Deep] Methodology Deep redirected',

  LOAD_CUSTOM_VARIABLES: '[Indicator] Load Custom Variables',
  LOAD_CUSTOM_VARIABLES_SUCCESS: '[Indicator] Load Custom Variables Success',

  REORDER_METHODOLOGY_DEEP: '[Methodology Deep] Reorder Methodology Deep',
  REORDER_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Reorder Methodology Deep Success',
  REORDER_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Reorder Methodology Deep Failure',
  UPDATE_COLLAPSED_METHODOLOGY_DEEP: '[Methodology Deep] Update Collapsed Methodology Deep',
  UPDATE_COLLAPSED_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Update Collapsed Methodology Deep Success',
  UPDATE_COLLAPSED_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Update Collapsed Methodology Deep Failure',
  SAVE_METHODOLOGY_DEEP: '[Methodology] Save Methodology Deep',
  SAVE_METHODOLOGY_DEEP_SUCCESS: '[Methodology] Save Methodology Deep Success',
  SAVE_METHODOLOGY_DEEP_FAILURE: '[Methodology] Save Methodology Deep Failure',
  UPDATE_METHODOLOGY_DEEP: '[Methodology] Update Methodology Deep',
  TOGGLE_DEFAULT_METHODOLOGY_DEEP: '[Methodology Deep] Toggle Default Methodology Deep',
  TOGGLE_DEFAULT_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Toggle Default Methodology Deep Success',
  TOGGLE_DEFAULT_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Toggle Default Methodology Deep Failure',
  UPDATE_METHODOLOGY_DEEP_BASIC_INFORMATION: '[Methodology Deep] Update basic informations',
  DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Delete Characteristic/Indicator from Methodology Deep',
  DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP_SUCCESS:
    '[Methodology Deep] Delete Characteristic/Indicator from Methodology Deep Success',
  DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP_FAILURE:
    '[Methodology Deep] Delete Characteristic/Indicator from Methodology Deep Failure',
  DELETE_ONLY_INDICATOR_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Delete only Indicator from Methodology Deep',
  TOGGLE_ADD_PHENOM_MODAL_METHODOLOGY_DEEP: '[Methodology Deep] Toggle Add Phenomenon Modal Methodology Deep',
  TOGGLE_ADD_EXTRA_DIMENSION_MODAL_METHODOLOGY_DEEP: '[Methodology Deep] Toggle Add Extra Dimension Modal Methodology Deep',
  UPDATE_CHARACTERISTIC_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Update Characteristic from Methodology Deep',

  UPSERT_INSPECTION_GROUP: '[Methodology Deep] Upsert Inspection Group',
  UPSERT_INSPECTION_GROUP_FAILURE: '[Methodology Deep] Upsert Inspection Group Failure',
  DELETE_INSPECTION_GROUP: '[Methodology Deep] Delete Inspection Group',
  DELETE_INSPECTION_GROUP_FAILURE: '[Methodology Deep] Delete Inspection Group Failure',
  ADD_PHENOMENA_TO_METHODOLOGY_DEEP: '[Methodology Deep] Add Phenomenon to Methodology Deep',
  ADD_PHENOMENA_TO_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Add Phenomenon to Methodology Deep Success',
  ADD_PHENOMENA_TO_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Add Phenomenon to Methodology Deep Failure',
  ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP: '[Methodology Deep] Add Extra Dimension to Methodology Deep',
  ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Add Extra Dimension to Methodology Deep Success',
  ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Add Extra Dimension to Methodology Deep Failure',
  DELETE_EXTRA_DIMENSION_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Add Extra Dimension to Methodology Deep',
  DELETE_EXTRA_DIMENSION_FROM_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Add Extra Dimension to Methodology Deep Success',
  DELETE_EXTRA_DIMENSION_FROM_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Add Extra Dimension to Methodology Deep Failure',
  DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Delete Independent Variable from Methodology Deep',
  DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Delete Independent Variable from Methodology Deep Success',
  DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Delete Independent Variable from Methodology Deep Failure',
  DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Delete Phenomenon from Methodology Deep',
  DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Delete Phenomenon from Methodology Deep Success',
  DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Delete Phenomenon from Methodology Deep Failure',
  TOGGLE_INSPECTION_GROUP_MODAL_METHODOLOGY_DEEP: '[Methodology Deep] Toggle Inspection Group Modal',

  CHANGE_TAB_ACTIVE: '[Methodology] Change Tab Active',
  TOGGLE_ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP: '[Methodology Deep] Toggle Add Characteristic Indicator',
  TOGGLE_CLONE_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP: '[Methodology Deep] Toggle Clone Characteristic Indicator',
  LOAD_CHARACTERISTIC_INDICATOR_COMPONENT_METHODOLOGY_DEEP: '[Methodology Deep] Load Characteristic Indicator List in Modal',

  ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP: '[Methodology Deep] Add Characteristics/Indicators to Methodology Deep',
  ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP_SUCCESS: '[Methodology Deep] Add Characteristics/Indicators to Methodology Deep Success',
  ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP_FAILURE: '[Methodology Deep] Add Characteristics/Indicators to Methodology Deep Failure',

  TOGGLE_SHOW_ON_INSPECTION_LAYOUT: '[Methodology Deep] Toggle Show On Inspection Layout',
  TOGGLE_SHOW_ON_INSPECTION_LAYOUT_SUCCESS: '[Methodology Deep] Toggle Show On Inspection Layout Success',
  TOGGLE_SHOW_ON_INSPECTION_LAYOUT_FAILURE: '[Methodology Deep] Toggle Show On Inspection Layout Failure',
  TOGGLE_SHOW_ON_TIMELINE: '[Methodology Deep] Toggle Show On Timeline',
  TOGGLE_SHOW_ON_TIMELINE_SUCCESS: '[Methodology Deep] Toggle Show On Timeline Success',
  TOGGLE_SHOW_ON_TIMELINE_FAILURE: '[Methodology Deep] Toggle Show On Timeline Failure',
  REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP: '[Methodology Deep] Remove Mult Phenon Indicator From Methodology Deep',
  REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP_SUCCESS:
    '[Methodology Deep] Remove Mult Phenon Indicator From Methodology Deep Success',
  REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP_FAILURE:
    '[Methodology Deep] Remove Mult Phenon Indicator From Methodology Deep Failure',

  TOGGLE_ADD_METHODOLOGY_DEEP_MODAL: '[Methdology deep] Toggle Modal Add New Methodology Deep',

  CLONE_METHODOLOGY: '[Methdology deep] Clone a Methodology Deep',

  CLONE_METHODOLOGY_SUCCESS: '[Methdology deep] Clone a Methodology Deep Success',

  CLONE_METHODOLOGY_FAILURE: '[Methdology deep] Clone a Methodology Deep Failure',

  UPDATE_CLONED_PHENOMENA_TOTALS: '[Methodology deep] Update cloned phenomena totals',
  RESET_CLONED_PHENOMENA_TOTALS: '[Methodology deep] Reset cloned phenomena totals',

  loadMethodologyDeep: (id: UUID, redirect = true) => ({
    type: actions.LOAD_METHODOLOGY_DEEP,
    payload: { id, redirect }
  }),

  redirectedMethodologyDeep: () => ({
    type: actions.METHODOLOGY_DEEP_REDIRECTED
  }),

  loadMethodologyDeepSuccess: (methodology: MethodologyDeep) => ({
    type: actions.LOAD_METHODOLOGY_DEEP_SUCCESS,
    payload: methodology
  }),

  loadMethodologyDeepFailure: (error: string) => ({
    type: actions.LOAD_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  loadCustomVariables: (methodology: MethodologyDeep) => ({
    type: actions.LOAD_CUSTOM_VARIABLES,
    payload: methodology
  }),

  loadCustomVariablesSuccess: (customVariables: CustomVariableType[]) => ({
    type: actions.LOAD_CUSTOM_VARIABLES_SUCCESS,
    payload: customVariables
  }),

  reorderMethodologyDeep: (id: UUID, source, destination: any) => ({
    type: actions.REORDER_METHODOLOGY_DEEP,
    payload: {
      id,
      source,
      destination
    }
  }),

  reorderMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.REORDER_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  reorderMethodologyDeepFailure: (error: any) => ({
    type: actions.REORDER_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  updateCollapsedMethodologyDeep: (id: UUID, collapsed: boolean) => ({
    type: actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP,
    payload: {
      id,
      collapsed
    }
  }),

  updateCollapsedMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  updateCollapsedMethodologyDeepFailure: (error: any) => ({
    type: actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  saveMethodologyDeep: (methodologyDeep: MethodologyDeep, plans?: UUID[], showNotification?: boolean) => ({
    type: actions.SAVE_METHODOLOGY_DEEP,
    payload: { methodologyDeep, plans, showNotification }
  }),

  saveMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.SAVE_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  saveMethodologyDeepFailure: (error: string, methodologyDeep: MethodologyDeep | undefined = undefined) => ({
    type: actions.SAVE_METHODOLOGY_DEEP_FAILURE,
    payload: { error, methodologyDeep }
  }),

  updateMethodologyDeep: (methodologyDeep: MethodologyDeep) => ({
    type: actions.UPDATE_METHODOLOGY_DEEP,
    payload: methodologyDeep
  }),

  toggleDefaultMethodologyDeep: () => ({
    type: actions.TOGGLE_DEFAULT_METHODOLOGY_DEEP
  }),

  updateBasicInformations: (payload: BasicFormPayload) => ({
    type: actions.UPDATE_METHODOLOGY_DEEP_BASIC_INFORMATION,
    payload: payload
  }),

  deleteIndependentVariableFromMethodologyDeep: (item: IndependentVariable) => ({
    type: actions.DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP,
    payload: item
  }),

  deleteIndependentVariableFromMethodologyDeepSuccess: (methodology: MethodologyDeep) => ({
    type: actions.DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP_SUCCESS,
    payload: methodology
  }),

  deleteIndependentVariableFromMethodologyDeepFailure: (error: string) => ({
    type: actions.DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  deleteCharacteristicIndicatorFromMethodologyDeep: (item: SimpleCharacteristicsIndicatorsProps) => ({
    type: actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP,
    payload: item
  }),

  deleteCharacteristicIndicatorFromMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  deleteCharacteristicIndicatorFromMethodologyDeepFailure: (error: string) => ({
    type: actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  deleteOnlyIndicatorFromMethodologyDeep: (item: SimpleCharacteristicsIndicatorsProps) => ({
    type: actions.DELETE_ONLY_INDICATOR_FROM_METHODOLOGY_DEEP,
    payload: item
  }),

  updateCharacteristicFromMethodologyDeep: (
    characteristic: Characteristic,
    defaultIndicator: Indicator,
    methodologyId?: UUID,
    orderedComponentId?: UUID,
    orderedPhenomenonId?: UUID
  ) => ({
    type: actions.UPDATE_CHARACTERISTIC_FROM_METHODOLOGY_DEEP,
    payload: {
      characteristic,
      defaultIndicator,
      methodologyId,
      orderedComponentId,
      orderedPhenomenonId
    }
  }),

  toggleAddCharacteristicIndicatorModal: (phenomenonInfo?: PhenomenonInfo) => ({
    type: actions.TOGGLE_ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP,
    payload: phenomenonInfo
  }),

  toggleCloneCharacteristicIndicatorDrawer: (phenomenonInfo?: PhenomenonInfo) => ({
    type: actions.TOGGLE_CLONE_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP,
    payload: phenomenonInfo
  }),

  toggleAddPhenomenonModal: (inspectionGroupId?: UUID) => ({
    type: actions.TOGGLE_ADD_PHENOM_MODAL_METHODOLOGY_DEEP,
    payload: inspectionGroupId
  }),

  toggleAddExtraDimensionModal: (inspectionGroupId?: UUID) => ({
    type: actions.TOGGLE_ADD_EXTRA_DIMENSION_MODAL_METHODOLOGY_DEEP,
    payload: inspectionGroupId
  }),

  addPhenomenaMethodologyDeep: (payload: any) => ({
    type: actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP,
    payload: payload
  }),

  addPhenomenaMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  addPhenomenaMethodologyDeepFailure: (error: string) => ({
    type: actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  addExtraDimensionMethodologyDeep: (payload: any) => ({
    type: actions.ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP,
    payload: payload
  }),

  addExtraDimensionMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  addExtraDimensionMethodologyDeepFailure: (error: string) => ({
    type: actions.ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  deleteExtraDimensionMethodologyDeep: (payload: any) => ({
    type: actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP,
    payload: payload
  }),

  deleteExtraDimensionMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  deleteExtraDimensionMethodologyDeepFailure: (error: string) => ({
    type: actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  deletePhenomenonFromMethodologyDeep: (phenomenonId: UUID) => ({
    type: actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP,
    payload: phenomenonId
  }),

  deletePhenomenonFromMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  deletePhenomenonFromMethodologyDeepFailure: (error: string) => ({
    type: actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  toggleInspectionGroupModal: (id?: UUID) => ({
    type: actions.TOGGLE_INSPECTION_GROUP_MODAL_METHODOLOGY_DEEP,
    payload: id
  }),

  upsertInspectionGroup: ({ inspectionGroupName, inspectionGroupId }) => ({
    type: actions.UPSERT_INSPECTION_GROUP,
    payload: { inspectionGroupName, inspectionGroupId }
  }),

  upsertInspectionGroupFailure: (error: string) => ({
    type: actions.UPSERT_INSPECTION_GROUP_FAILURE,
    payload: error
  }),

  deleteInspectionGroupMethodologyDeep: (inspectionGroupId: UUID) => ({
    type: actions.DELETE_INSPECTION_GROUP,
    payload: inspectionGroupId
  }),

  deleteInspectionGroupMethodologyDeepFailure: (error: string) => ({
    type: actions.DELETE_INSPECTION_GROUP_FAILURE,
    payload: error
  }),

  changeTabActive: (activeKey: string) => ({
    type: actions.CHANGE_TAB_ACTIVE,
    payload: activeKey
  }),

  addCharacteristicIndicatorMethodologyDeep: (selectedComponents: UUID, orderedPhenomenonId: UUID, phenomenonId: UUID) => ({
    type: actions.ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP,
    payload: {
      selectedComponents,
      orderedPhenomenonId,
      phenomenonId
    }
  }),

  addCharacteristicIndicatorsSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  addCharacteristicsIndicatorsFailure: (error: string) => ({
    type: actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  toggleShowOnInspectionLayout: (id: UUID) => ({
    type: actions.TOGGLE_SHOW_ON_INSPECTION_LAYOUT,
    payload: id
  }),

  toggleShowOnInspectionLayoutFailure: (error: string) => ({
    type: actions.TOGGLE_SHOW_ON_INSPECTION_LAYOUT_FAILURE,
    payload: error
  }),

  toggleShowOnTimeline: (id: UUID) => ({
    type: actions.TOGGLE_SHOW_ON_TIMELINE,
    payload: id
  }),

  toggleShowOnTimelineFailure: (error: string) => ({
    type: actions.TOGGLE_SHOW_ON_TIMELINE_FAILURE,
    payload: error
  }),

  removeMultPhenonIndicatorFromMethodologyDeep: (indicatorId: UUID) => ({
    type: actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP,
    payload: indicatorId
  }),

  removeMultPhenonIndicatorFromMethodologyDeepSuccess: (methodologyDeep: MethodologyDeep) => ({
    type: actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP_SUCCESS,
    payload: methodologyDeep
  }),

  removeMultPhenonIndicatorFromMethodologyDeepFailure: (error: string) => ({
    type: actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP_FAILURE,
    payload: error
  }),

  toggleAddMethodologyDeepModal: (cloneModal: boolean, cloneMethodology?: Methodology) => ({
    type: actions.TOGGLE_ADD_METHODOLOGY_DEEP_MODAL,
    payload: { cloneModal: cloneModal, cloneMethodology: cloneMethodology }
  }),

  cloneMethodologyDeep: (methodologyDeep: MethodologyDeep, plans?: UUID[]) => ({
    type: actions.CLONE_METHODOLOGY,
    payload: { methodologyDeep, plans }
  }),

  cloneMethodologyDeepSuccess: () => ({
    type: actions.CLONE_METHODOLOGY_SUCCESS
  }),

  cloneMethodologyDeepFailure: (error: string) => ({
    type: actions.CLONE_METHODOLOGY_FAILURE,
    payload: error
  }),

  updateClonedPhenomenaTotals: (clonedVariables: number, clonedIndicators: number, clonedPhenomena: number) => ({
    type: actions.UPDATE_CLONED_PHENOMENA_TOTALS,
    payload: {
      clonedVariables,
      clonedIndicators,
      clonedPhenomena
    }
  }),

  resetClonedPhenomenaTotals: () => ({
    type: actions.RESET_CLONED_PHENOMENA_TOTALS
  })
};

export default actions;

export const MethodologyDeepActions = actions;
