import { Dictionary } from 'redux-ngrx-entity';
import { I18nMap, UUID } from '../app/basicModels';
import { buildMap, everyMatch, findObjectById, someMatch, uuid } from '../../helpers';
import { getValueLanguage } from '../language/utils';
import { Company } from '../company/models';
import { Crop } from '../crop/models';
import {
  AnalyticContext,
  CustomIndicator,
  FeatureFlag,
  InspectionLayout,
  Methodology,
  OrderedCategory,
  OrderedComponent,
  OrderedExtraDimension,
  OrderedIndependentVariable,
  OrderedInspectionGroup,
  OrderedPhenomenon,
  VariableValidation
} from '../methodology/models';
import { Characteristic, FeatureFlagEnum, Indicator, ProductSet, DefaultExpressionType, IndependentVariable } from '../models';
import { Phenomenon, PhenomenonSingle, PhenomenonSet } from '../phenomenon/models';
import { SeasonTree } from '../season/models';
import {
  BasicFormPayload,
  CategoryDeep,
  CharacteristicDeep,
  ClassName,
  ComponentDeep,
  ConvertToMethodologyDeepProps,
  CustomVariablesGetDTO,
  ExtraDimensionsDeep,
  IndicatorDeep,
  InspectionGroupDeep,
  InspectionLayoutDeep,
  MethodologyDeep,
  PhenomenonDeep,
  SeasonDeep,
  SeasonTreeDeep
} from './models';
import {
  filterByIndicator,
  getCategoryDeep,
  getCategoryIds,
  getCharacteristicComponentById,
  getCharacteristicDeep,
  getCharacteristicIds,
  getCharacteristics,
  getComponentDeepWithProperties,
  getExtraDimensionsDeep,
  getIds,
  getIndicatorIds,
  getIndicatorsFromAnalyticContext,
  getInspectionGroupDeep,
  getInspectionLayoutDeep,
  getMethodologyDeep,
  getObjectById,
  getPhenomenonDeep,
  getSingleIndicatorDeep,
  isObjectCanonical,
  isObjectReferenceCanonical,
  mapSeasonsTreeSeasonPropertiesSeasonAreasDeep
} from './utils';
import { Phenomena } from '../../components/addPhenomemonModal/addPhenomenonModal';
import { defaultProductSet } from './mocks';
import { uniqWith, isEqual, remove, cloneDeep, isEmpty } from 'lodash';
import { message } from 'antd';
import { buildComponentDeep } from '../../containers/FixedPointCategories/utils';
import { SimpleCharacteristicsIndicatorsProps } from '../../components/characteristicsIndicators/models/characteristicsIndicators.model';
import {
  getIndicatorCharacteristics,
  filterIndicatorsInCharacteristicComponents,
  isMultPhenonIndicator,
  isSingleIndicator
} from '../indicator/utils';
import { urlBuilder } from '../../helpers/url-builder/url';
import CONFIG from '../../settings';
import axios from 'axios';

function validate(
  methodology,
  phenomenonsTree,
  company: Company | undefined,
  crops: Crop[] | undefined,
  seasonTree: SeasonTree[] | undefined
) {
  return (
    !methodology || phenomenonsTree === undefined || phenomenonsTree === null || !company || !crops || crops.length === 0 || !seasonTree
  );
}

export const mapIndicatorsDeepByPhenomena = (indicators: IndicatorDeep[]) => {
  const indicatorsByPhenomena = {};
  indicators.forEach(indicator => {
    indicatorsByPhenomena[indicator.phenomenonIds![0]] = indicatorsByPhenomena[indicator.phenomenonIds![0]] || [];
    indicatorsByPhenomena[indicator.phenomenonIds![0]].push(indicator);
  });
  return indicatorsByPhenomena;
};

export const getCustomVariables = (cropId: string, companyId: string) => {
  const demeterBaseUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}`;
  const url = urlBuilder(demeterBaseUrl)
    .path('api')
    .path('v1')
    .path('custom-variable')
    .query('crop', cropId)
    .query('class_name', 'CustomDoubleVariable')
    .build();

  return axios.get<CustomVariablesGetDTO>(url, {
    headers: {
      'X-Company-Id': companyId
    }
  });
};

export const isWrongIndicator = (indicator: Indicator, ids: UUID[]) => {
  const indicatorCharacteristics = getIndicatorCharacteristics(indicator);
  return !everyMatch(indicatorCharacteristics, ids) || !indicatorCharacteristics.length;
};

export const isWrongIndicatorDeep = (indicator: IndicatorDeep, ids: UUID[]) => {
  return !everyMatch(indicator.characteristicsIds, ids) || !indicator.characteristicsIds!.length;
};

export const convertToMethodologyDeep = ({
  methodology,
  phenomenonsTree,
  company,
  crops,
  seasonTree,
  independentVariables,
  extraDimensions,
  flagPreventIndicatorDuplicity
}: ConvertToMethodologyDeepProps): MethodologyDeep => {
  if (validate(methodology, phenomenonsTree, company, crops, seasonTree)) throw new Error('Methodology could not be created');
  const methodologyDeep = getMethodologyDeep(
    methodology,
    company,
    crops!.find(crop => crop.id === methodology.crop_id),
    seasonTree
  );
  methodologyDeep.defaultIndicatorsNotFound = [];
  const independentVariablesIds =
    methodology.inspection_layout && methodology.inspection_layout.independent_variables
      ? methodology.inspection_layout.independent_variables.map(iv => iv.id)
      : [];
  const characteristicIds = (methodology.inspection_layout && methodology.inspection_layout.characteristic_ids) || [];
  const variableIds = [...characteristicIds, ...independentVariablesIds];
  if (methodology.inspection_layout) {
    methodologyDeep.inspectionLayout = getInspectionLayoutDeep(
      methodology.inspection_layout,
      independentVariables as IndependentVariable[]
    );
    methodologyDeep.inspectionLayout.inspectionGroups = methodology.inspection_layout.ordered_inspection_groups?.map(inspectionGroup => {
      const inspectionGroupDeep: InspectionGroupDeep = getInspectionGroupDeep(inspectionGroup);
      inspectionGroupDeep.categories = inspectionGroup.ordered_categories.map(category => {
        const categoryDeep: CategoryDeep = getCategoryDeep(category);
        categoryDeep.name = getCategoryName(phenomenonsTree, categoryDeep.categoryId);
        const orderedPhenomena = category.ordered_phenomenons.map(orderedPhenomenon => {
          const phenomenonInTree = getObjectById<Phenomenon>(phenomenonsTree, orderedPhenomenon.component_id);
          if (!phenomenonInTree) {
            return undefined;
          }
          const { phenomenonDeep, indicatorsNotFound } = createPhenomenonDeep(
            orderedPhenomenon,
            methodology.analytic_context_dto,
            phenomenonInTree,
            variableIds,
            flagPreventIndicatorDuplicity
          );
          methodologyDeep.defaultIndicatorsNotFound?.push(...indicatorsNotFound);
          return phenomenonDeep;
        });
        categoryDeep.phenomenons = orderedPhenomena.filter(op => !!op);
        return categoryDeep;
      });
      inspectionGroupDeep.extraDimensions = {
        id: uuid(),
        collapsed: false,
        components:
          inspectionGroup.ordered_extra_dimensions &&
          inspectionGroup.ordered_extra_dimensions.map(orderedExtraDimensions => {
            const extraDimensionDeep: ExtraDimensionsDeep = getExtraDimensionsDeep(orderedExtraDimensions, extraDimensions);
            return extraDimensionDeep;
          })
      };

      return inspectionGroupDeep;
    });
  }
  if (methodology.analytic_context_dto && methodology.analytic_context_dto.id) {
    methodologyDeep.multiPhenomenaIndicators = getMultiPhenomenonIndicators(methodology, phenomenonsTree);
    methodologyDeep.analyticContextId = methodology.analytic_context_dto.id;
  }

  if (
    methodology.analytic_context_dto &&
    methodology.analytic_context_dto.custom_indicator_dtos &&
    methodology.inspection_layout &&
    methodology.inspection_layout.ordered_inspection_groups
  ) {
    const singlePhenomenonIndicators = phenomenonsTree.flatMap(p => getIndicatorsSinglePhenomenon(p));
    const ids = methodology.analytic_context_dto.custom_indicator_dtos.map(i => i.indicator_id);
    const customIndicators = ids.map(i => getObjectById(singlePhenomenonIndicators, i)).filter(i => !!i);
    const wrongIndicators = customIndicators.filter(i => isWrongIndicator(i, variableIds));
    methodologyDeep.wrongIndicators = wrongIndicators.map(i => buildWrongIndicatorDeep(i));

    methodologyDeep.characteristicIds = methodology.inspection_layout?.characteristic_ids || [];
    methodologyDeep.variablesValidation = methodology.inspection_layout?.variables_validation || [];
  }

  return methodologyDeep;
};

export const removePhenomenonDeepById = (id: UUID, phenomenaDeep: PhenomenonDeep[] | undefined) => {
  const phenomena = phenomenaDeep || [];

  if (phenomena.length === 0) {
    return phenomena;
  }

  return phenomena.filter(p => p.phenomenonId !== id).map((p, index) => ({ ...p, priority: index }));
};

const setPhenomenonInCorrectyCategory = (category: CategoryDeep, phenomenonSet: PhenomenonSet): CategoryDeep => {
  const phenomenaOfCategory = category.phenomenons || [];
  if (category.categoryId === phenomenonSet.phenomenon.category!.id) {
    const phenomenonDeep = { ...phenomenonSet.phenomenonDeep, priority: phenomenaOfCategory.length };
    category.phenomenons = [...phenomenaOfCategory, phenomenonDeep];
  }
  return category;
};

export function fixPhenomenaInCorrectCategories(inspectionGroup: InspectionGroupDeep, phenomena: PhenomenonSet[]) {
  const updatedInspGroup: InspectionGroupDeep = cloneDeep(inspectionGroup);
  const categories: CategoryDeep[] = updatedInspGroup.categories || [];
  const categoriesIds = getCategoryIds(categories);

  phenomena.forEach((p: PhenomenonSet) => {
    const id = p.phenomenonDeep?.phenomenonId;
    if (id) {
      categories.forEach(c => {
        if (c.phenomenons) {
          c.phenomenons = removePhenomenonDeepById(id, c.phenomenons || []);
        }
      });

      const phenomenonCategoryId = p?.phenomenon?.category?.id || '';

      if (categoriesIds.includes(phenomenonCategoryId)) {
        updatedInspGroup.categories = categories.map(cat => setPhenomenonInCorrectyCategory(cat, p));
      } else {
        const newCategory: CategoryDeep = buildNewCategoryDeepFromPhenomenon(p, categories.length);
        updatedInspGroup.categories = [...categories, newCategory];
      }
    }
  });

  return updatedInspGroup;
}

function getCategoryName(phenomenonsTree: Phenomenon[], categoryId: UUID) {
  const categories = phenomenonsTree.map(phenomenon => phenomenon.category);
  const category = findObjectById(categories, categoryId);
  return category ? category.name : undefined;
}

const createPhenomenonDeep = (
  orderedPhenomenon: OrderedPhenomenon,
  analyticContext: AnalyticContext,
  phenomenon: Phenomenon,
  variableIds: UUID[],
  flagPreventIndicatorDuplicity?: boolean
) => {
  const { ordered_components } = orderedPhenomenon;
  const indicatorsNotFound: any = [];

  function validateParameters() {
    const customIndicatorIds = analyticContext.custom_indicator_dtos.map(indicator => indicator.indicator_id);
    orderedPhenomenon.ordered_components
      .filter(component => component.class_name.includes(ClassName.INDICATOR))
      .forEach(indicator => {
        if (!customIndicatorIds.includes(indicator.component_id)) {
          console.log(`Indicator with id ${indicator.component_id} is not present in analytic context.`);
        }
      });
    orderedPhenomenon.ordered_components
      .filter(component => component.class_name.includes(ClassName.CHARACTERISTIC))
      .forEach(c => {
        const characteristic = getObjectById(phenomenon.characteristics ?? [], c.component_id);

        if (characteristic?.default_indicator_id && !customIndicatorIds.includes(characteristic.default_indicator_id)) {
          indicatorsNotFound.push({
            indicatorId: characteristic.default_indicator_id,
            characteristic
          });
        }
      });
  }

  if (!phenomenon) {
    console.warn(`Phenomenon tree not found for phenomenon with id ${orderedPhenomenon.component_id}.`);
    return {
      phenomenonDeep: undefined,
      indicatorsNotFound: []
    };
  }
  validateParameters();

  const phenomenonDeep: PhenomenonDeep = getPhenomenonDeep(orderedPhenomenon);
  phenomenonDeep.name = phenomenon.name;
  phenomenonDeep.comesFromCanonical = isObjectCanonical(phenomenon);
  const components = createComponentsDeep(ordered_components, analyticContext, phenomenon, variableIds, flagPreventIndicatorDuplicity);
  phenomenonDeep.components = components;
  phenomenonDeep.scientificName = phenomenon.short_description;

  return {
    phenomenonDeep,
    indicatorsNotFound
  };
};

function createComponentsDeep(
  orderedComponents: OrderedComponent[],
  analyticContext: AnalyticContext,
  phenomenonTree: Phenomenon,
  variableIds: UUID[],
  flagPreventIndicatorDuplicity?: boolean
) {
  const characteristics = getCharacteristics(orderedComponents);
  const singleIndicators = getIndicatorsSinglePhenomenon(phenomenonTree);
  const componentsCharacteristicsIds = characteristics.map(c => c.component_id);

  const indicators = getIndicatorsFromAnalyticContext(
    analyticContext.custom_indicator_dtos,
    singleIndicators.map(i => i.id!)
  );

  const components: ComponentDeep[] = [];

  const characteristicComponents = characteristics
    .filter(characteristic => getObjectById(phenomenonTree.characteristics ?? [], characteristic.component_id))
    .map(characteristic => createCharacteristicComponentDeep(phenomenonTree, characteristic, indicators, orderedComponents));

  const defaultIndicatorIds = characteristicComponents
    .filter(component => !!component.indicator)
    .map(component => component.indicator!.indicatorId);

  const indicatorsNotCreated = indicators.filter(indicator => !defaultIndicatorIds.includes(indicator.indicator_id));

  const indicatorsNotCreatedFully = indicatorsNotCreated
    .map(i => getObjectById(phenomenonTree.indicators ?? [], i.indicator_id))
    .filter(i => !!i);

  const indicatorsMap = buildMap(indicatorsNotCreatedFully);
  /**
   * Function to check what id of characteristic or independent variable matchs on indicator
   */
  const consistentIndicatorComponents = indicatorsNotCreated
    .filter(
      i =>
        indicatorsMap[i.indicator_id] &&
        !isWrongIndicator(indicatorsMap[i.indicator_id], variableIds) &&
        someMatch([...componentsCharacteristicsIds, ...variableIds], getIndicatorCharacteristics(indicatorsMap[i.indicator_id]))
    )
    .map(indicator => createIndicatorOnlyComponentDeep(phenomenonTree, indicator, orderedComponents));

  const indicatorComponentsWithCharacteristic = flagPreventIndicatorDuplicity
    ? filterIndicatorsInCharacteristicComponents(characteristicComponents, consistentIndicatorComponents)
    : consistentIndicatorComponents;

  components.push(...characteristicComponents);
  components.push(...indicatorComponentsWithCharacteristic);

  return components;
}

function createCharacteristicComponentDeep(
  phenomenonTree: Phenomenon,
  characteristic: OrderedComponent,
  indicators: CustomIndicator[],
  allOrderedComponents: OrderedComponent[]
) {
  const characteristicDto = getObjectById(phenomenonTree.characteristics ?? [], characteristic.component_id);

  if (!characteristicDto) throw new Error('Characteristic was not found in Phenomenons Tree');

  let indicatorDeep;

  if (characteristicDto.default_indicator_id) {
    const defaultIndicator = indicators.find(item => item.indicator_id === characteristicDto.default_indicator_id);
    const phenomenonTreeIndicator = getObjectById(phenomenonTree.indicators ?? [], characteristicDto.default_indicator_id);

    if (phenomenonTreeIndicator) {
      const inspectionLayoutIndicator = allOrderedComponents.find(
        orderedComponent => orderedComponent.component_id === characteristicDto.default_indicator_id
      );
      indicatorDeep = getSingleIndicatorDeep(phenomenonTreeIndicator, true, defaultIndicator, inspectionLayoutIndicator);
    }
  }
  const characteristicDeep = getCharacteristicDeep(characteristic, characteristicDto);

  return getComponentDeepWithProperties(indicatorDeep, characteristicDeep, characteristicDeep.priority);
}

function createIndicatorOnlyComponentDeep(
  phenomenonTree: Phenomenon,
  indicator: CustomIndicator,
  allOrderedComponents: OrderedComponent[]
) {
  const phenomenonTreeIndicator = getObjectById(phenomenonTree.indicators ?? [], indicator.indicator_id);

  const inspectionLayoutIndicator = allOrderedComponents.find(orderedComponent => orderedComponent.component_id === indicator.indicator_id);

  let indicatorDeep: IndicatorDeep | undefined;
  if (phenomenonTreeIndicator) {
    indicatorDeep = getSingleIndicatorDeep(phenomenonTreeIndicator, false, indicator, inspectionLayoutIndicator);
  }

  return getComponentDeepWithProperties(
    indicatorDeep,
    undefined,
    inspectionLayoutIndicator ? inspectionLayoutIndicator.priority : undefined
  );
}

function getIndicatorsSinglePhenomenon(phenomenonTree: Phenomenon) {
  return phenomenonTree.indicators
    ? phenomenonTree.indicators.filter(indicator => indicator.phenomenon_ids && indicator.phenomenon_ids.length === 1)
    : [];
}

function getMultiPhenomenonIndicators(methodology, phenomenaTree): IndicatorDeep[] {
  const indicators = methodology.analytic_context_dto.custom_indicator_dtos;

  return indicators.reduce(function (multiPhenomenonIndicators: IndicatorDeep[], indicator) {
    const phenomenon = getPhenomenonTreeThatContainsIndicator(phenomenaTree, indicator.indicator_id);

    if (phenomenon) {
      const phenomenonIndicator = getObjectById(phenomenon.indicators ?? [], indicator.indicator_id);

      if (phenomenonIndicator && isIndicatorMultiPhenomena(phenomenonIndicator)) {
        multiPhenomenonIndicators.push(getSingleIndicatorDeep(phenomenonIndicator, false, indicator));
      }
    }
    return multiPhenomenonIndicators;
  }, []);
}

function getPhenomenonTreeThatContainsIndicator(phenomenaTree: Phenomenon[], indicatorId: UUID): Phenomenon | undefined {
  return phenomenaTree.find(phenomenon => phenomenon.indicators && phenomenon.indicators.map(ind => ind.id).includes(indicatorId));
}

function isIndicatorMultiPhenomena(phenomenaTreeIndicator) {
  return phenomenaTreeIndicator.phenomenon_ids.length > 1;
}

export const convertFromMethodologyDeep = (methodologyDeep: MethodologyDeep) => {
  const seasonsTreeMethodologyDeep =
    methodologyDeep.seasonsTree &&
    methodologyDeep.seasonsTree.seasons &&
    methodologyDeep.seasonsTree.seasonAreas &&
    methodologyDeep.seasonsTree.seasonProperties
      ? methodologyDeep.seasonsTree
      : {
          seasons: [],
          seasonAreas: [],
          seasonProperties: []
        };

  const methodologyReal: Methodology = {
    isNew: methodologyDeep.isNew,
    id: methodologyDeep.id,
    name: methodologyDeep.name,
    company_id: methodologyDeep.company!.id,
    description: methodologyDeep.description,
    crop_id: methodologyDeep.crop!.id,
    default_methodology: !!methodologyDeep.defaultMethodology,
    season_area_ids: seasonsTreeMethodologyDeep.seasonAreas!.map(s => s.id!),
    season_ids: seasonsTreeMethodologyDeep.seasons!.map(s => s.id!),
    season_property_ids: seasonsTreeMethodologyDeep.seasonProperties!.map(s => s.id!),
    product_set_id: methodologyDeep.productSet && methodologyDeep.productSet.id,
    inspection_layout: convertFromInspectionLayoutDeep(methodologyDeep.inspectionLayout),
    analytic_context_dto: getAnalyticContext(
      methodologyDeep.analyticContextId!,
      methodologyDeep.inspectionLayout!,
      methodologyDeep.multiPhenomenaIndicators!,
      methodologyDeep.wrongIndicators!
    )
  };
  return methodologyReal;
};

export function getIndependentVariablesOrdered(variables?: IndependentVariable[]) {
  if (!variables) return [];
  return variables.map<OrderedIndependentVariable>((v, i) => {
    return {
      id: uuid(),
      class_name: ClassName.CHARACTERISTIC,
      component_id: v.id,
      priority: i
    };
  });
}

function convertFromInspectionLayoutDeep(inspectionLayoutDeep?: InspectionLayoutDeep): InspectionLayout | undefined {
  if (!inspectionLayoutDeep) {
    return undefined;
  }

  const oInspectionGroups = parseToOrderedInspectionGroups(inspectionLayoutDeep.inspectionGroups);

  const oCategories: OrderedCategory[] = oInspectionGroups.map(ig => ig.ordered_categories).flat();

  const oPhenomena: OrderedPhenomenon[] = oCategories.map(orderedCategory => orderedCategory.ordered_phenomenons).flat();

  const oComponents: OrderedComponent[] = oPhenomena.map(orderedComponent => orderedComponent.ordered_components).flat();

  const inspectionLayout: InspectionLayout = {
    id: inspectionLayoutDeep.id,
    ordered_inspection_groups: oInspectionGroups,
    characteristic_ids: getCharacteristicIds(oComponents),
    independent_variables: getIndependentVariablesOrdered(inspectionLayoutDeep.independentVariables),
    indicator_ids: getIndicatorIds(oComponents),
    variables_validation: inspectionLayoutDeep.variablesValidation
  };
  return inspectionLayout;
}

function getAnalyticContext(
  analyticContextId: UUID,
  inspectionLayout: InspectionLayoutDeep,
  multiPhenomenaIndicators: IndicatorDeep[],
  wrongIndicators: IndicatorDeep[]
) {
  if (analyticContextId) {
    const analyticContext: AnalyticContext = {
      id: analyticContextId,
      custom_indicator_dtos: []
    };

    if (inspectionLayout) {
      const categories: CategoryDeep[] = inspectionLayout.inspectionGroups
        .filter(inspectionGroup => !!inspectionGroup.categories)
        .map(ig => ig.categories!)
        .flat();

      const phenomena: PhenomenonDeep[] = categories
        .filter(category => !!category.phenomenons)
        .map(c => c.phenomenons!)
        .flat();

      const indicators: IndicatorDeep[] = phenomena
        .filter(phenomenon => !!phenomenon.components)
        .map(p => p.components!)
        .flat()
        .filter(component => component && component.indicator)
        .map(c => c.indicator!);

      analyticContext.custom_indicator_dtos = getCustomIndicators([...indicators]);
    }
    if (multiPhenomenaIndicators && multiPhenomenaIndicators.length > 0) {
      const customMultiPhenomenonIndicators = getCustomIndicators([...multiPhenomenaIndicators]);
      if (customMultiPhenomenonIndicators && customMultiPhenomenonIndicators.length > 0)
        analyticContext.custom_indicator_dtos.push(...customMultiPhenomenonIndicators);
    }

    if (wrongIndicators && wrongIndicators.length) {
      const customWrongIndicators = getCustomIndicators([...wrongIndicators]);
      if (customWrongIndicators && customWrongIndicators.length) {
        analyticContext.custom_indicator_dtos.push(...customWrongIndicators);
      }
    }
    return analyticContext;
  }
  return undefined;
}

function getCustomIndicators(indicators: IndicatorDeep[]) {
  if (indicators) {
    const customIndicators: CustomIndicator[] = indicators.map(indicator => {
      const featureFlags: FeatureFlag[] = [];
      if (!indicator.showOnTimeline) featureFlags.push({ label: FeatureFlagEnum.NO_TIMELINE });
      if (!indicator.showOnHeatmap) featureFlags.push({ label: FeatureFlagEnum.NO_HEATMAP });
      return {
        indicator_id: indicator.indicatorId,
        diagnostics: indicator.diagnostics,
        feature_flags: featureFlags
      };
    });
    return uniqWith(customIndicators, isEqual);
  }
  return [];
}

const parseToOrderedInspectionGroups = (inspectionGroups: InspectionGroupDeep[]): OrderedInspectionGroup[] => {
  const orderedInspectionGroups = inspectionGroups.map(inspectionGroup => {
    return {
      id: inspectionGroup.id,
      name: inspectionGroup.name!,
      component_id: inspectionGroup.inspectionGroupId,
      priority: inspectionGroup.priority,
      ordered_extra_dimensions:
        inspectionGroup.extraDimensions && inspectionGroup.extraDimensions.components
          ? parseToOrderedExtraDimensions(inspectionGroup.extraDimensions.components)
          : [],
      ordered_categories: inspectionGroup.categories ? parseToOrderedCategories(inspectionGroup.categories) : []
    };
  });
  return orderedInspectionGroups;
};

const parseToOrderedCategories = (categories: CategoryDeep[]): OrderedCategory[] => {
  const orderedCategories = categories.map(category => {
    return {
      id: category.id,
      component_id: category.categoryId,
      priority: category.priority,
      ordered_phenomenons: category.phenomenons ? parseToOrderedPhenomena(category.phenomenons) : []
    };
  });
  return orderedCategories;
};

const parseToOrderedExtraDimensions = (extraDimension: ExtraDimensionsDeep[]): OrderedExtraDimension[] => {
  const orderedExtraDimensions = extraDimension.map(ed => {
    return {
      extra_dimension_id: ed.id,
      priority: ed.priority
    };
  });
  return orderedExtraDimensions;
};

const parseToOrderedPhenomena = (phenomena: PhenomenonDeep[]): OrderedPhenomenon[] => {
  const orderedPhenomena = phenomena.map(phenomenon => {
    return {
      id: phenomenon.id,
      component_id: phenomenon.phenomenonId,
      priority: phenomenon.priority!,
      ordered_components: phenomenon.components ? parseToOrderedComponents(phenomenon.components) : []
    };
  });
  return orderedPhenomena;
};

const parseToOrderedComponents = (components: ComponentDeep[]): OrderedComponent[] => {
  if (!components || components.length === 0) return [];
  const orderedComponents: OrderedComponent[] = [];
  components
    .filter(c => c.characteristic || c.indicator!.showOnInspectionLayout)
    .forEach(component => {
      const indicatorDeep = component.indicator;
      const characteristicDeep = component.characteristic;

      if (indicatorDeep && indicatorDeep.showOnInspectionLayout) {
        orderedComponents.push({
          id: indicatorDeep.id,
          component_id: indicatorDeep.indicatorId,
          priority: component.priority!,
          class_name: ClassName.INDICATOR
        });
      }

      if (characteristicDeep) {
        orderedComponents.push({
          id: characteristicDeep.id,
          component_id: characteristicDeep.characteristicId,
          priority: component.priority!,
          class_name: ClassName.CHARACTERISTIC
        });
      }
    });
  return orderedComponents.flat();
};

export function parseDataToDeep(
  data,
  companies: Dictionary<Company>,
  crops: Dictionary<Crop>,
  seasonsTree: SeasonTree[],
  productSets: ProductSet[]
) {
  const methodologyDeep: MethodologyDeep = {
    isNew: true,
    name: { localized_strings: { en: data.name } },
    company: {
      id: data.company,
      name: companies[data.company].name
    },
    crop: {
      id: data.crop,
      name: crops[data.crop].name
    },
    description: { localized_strings: { en: data.description } },
    seasonsTree: parseSeasonIdsToSeasonsDeep(data.seasons, seasonsTree),
    productSet: productSets.find(p => p.id === data.productSet),
    analyticContextId: uuid(),
    inspectionLayout: {
      id: uuid(),
      inspectionGroups: []
    }
  };
  return methodologyDeep;
}

export function parseSeasonIdsToSeasonsDeep(seasonIds: UUID[], seasonsTree: SeasonTree[]) {
  const seasonsDeep: SeasonTreeDeep = {
    seasons: [],
    seasonAreas: [],
    seasonProperties: []
  };
  seasonIds.forEach(seasonId => {
    const seasons = seasonsTree.map(s => s.season);
    const seasonSearch = seasons.find(s => s.id === seasonId);
    if (seasonSearch) {
      seasonsDeep.seasons?.push({ id: seasonId, name: seasonSearch.name });
    } else {
      const seasonProperties = seasonsTree.map(s => s.seasonProperties).flat();
      const seasonPropertySearch = seasonProperties.find(s => s.property.id === seasonId);
      if (seasonPropertySearch) {
        seasonsDeep.seasonProperties?.push({
          id: seasonId,
          name: seasonPropertySearch.property.name
        });
      } else {
        const seasonAreas = seasonProperties.map(s => s.seasonAreas).flat();
        const seasonAreaSearch = seasonAreas.find(s => s.area.id === seasonId);
        if (seasonAreaSearch) {
          seasonsDeep.seasonAreas?.push({
            id: seasonId,
            name: seasonAreaSearch.area.name
          });
        }
      }
    }
  });
  return seasonsDeep;
}

export function createComponentsToBeAdded(
  selectedComponents: UUID[],
  allComponents: ComponentDeep[],
  characteristicsFromMethodology: UUID[]
): ComponentDeep[] {
  const onlyIndicators: ComponentDeep[] = allComponents.filter(c => filterByIndicator(c));

  const componentsForAdd: ComponentDeep[] = [];
  selectedComponents.forEach(selectedComponent => {
    const componentIndicator = onlyIndicators.find(i => i.indicator?.indicatorId === selectedComponent);
    if (componentIndicator) {
      componentIndicator.indicator?.characteristicsIds?.forEach(characteristicId => {
        if (!characteristicsFromMethodology.includes(characteristicId) && !selectedComponents.includes(characteristicId)) {
          const componentToAdd = getCharacteristicComponentById(
            allComponents,
            characteristicId,
            getValueLanguage(componentIndicator.indicator?.name)
          );
          componentsForAdd.push(componentToAdd);
        }
      });
      componentsForAdd.push(componentIndicator);
    } else if (!characteristicsFromMethodology.includes(selectedComponent)) {
      const componentToAdd = getCharacteristicComponentById(allComponents, selectedComponent);
      componentsForAdd.push(componentToAdd);
    }
  });

  return componentsForAdd;
}

export function checkIfCharacteristicIsUsedAnotherComponent(components: ComponentDeep[], characteristicId: UUID): string[] {
  const usedCharacteristic: string[] = [];
  if (components && components.length > 0 && characteristicId) {
    components.forEach(component => {
      if (
        (!component.characteristic || (component.characteristic && component.characteristic.characteristicId !== characteristicId)) &&
        component.indicator &&
        component.indicator.characteristicsIds &&
        component.indicator.characteristicsIds.indexOf(characteristicId) > -1
      ) {
        usedCharacteristic.push(getValueLanguage(component.indicator.name));
      }
    });
  }
  return usedCharacteristic;
}

export const removeIndicatorFromMethodologyDeep = (methodologyDeep: MethodologyDeep, indicatorId: UUID) => {
  const methodology = cloneDeep(methodologyDeep);
  if (methodology.inspectionLayout) {
    methodology.inspectionLayout.inspectionGroups.forEach(ig => {
      ig.categories?.forEach(category => {
        category.phenomenons?.forEach(phenomenon => {
          phenomenon.components = (phenomenon.components ?? []).filter(c => c.characteristic || c.indicator?.indicatorId !== indicatorId);
        });
      });
    });
  }
  return methodology;
};

const isToDelete = (
  component: ComponentDeep,
  componentId: UUID | undefined,
  indicatorId: UUID | undefined,
  characteristicId: UUID | undefined,
  flagPreventIndicatorDuplicity?: boolean
) => {
  const deleteAllDuplicatedIndicators = flagPreventIndicatorDuplicity
    ? true
    : !!(component?.indicator?.id === componentId || component?.indicator?.wrongConfiguration);
  return (
    (component.indicator && component.indicator.indicatorId === indicatorId && deleteAllDuplicatedIndicators) ||
    (component.characteristic &&
      component.characteristic.characteristicId === characteristicId &&
      component.characteristic.id === componentId)
  );
};
export function removeComponentByCharacteristicIdAndIndicatorId(
  components: ComponentDeep[],
  characteristicId?: UUID,
  indicatorId?: UUID,
  componentId?: UUID,
  flagPreventIndicatorDuplicity?: boolean
) {
  return (components || []).filter(c => !isToDelete(c, componentId, indicatorId, characteristicId, flagPreventIndicatorDuplicity));
}

function getOrderedMethodologyDeep(methodology: MethodologyDeep, { id, source, destination }) {
  const orderedMethodology = { ...methodology };
  if (orderedMethodology.id === id)
    orderedMethodology.inspectionLayout!.inspectionGroups = reorder(
      orderedMethodology.inspectionLayout!.inspectionGroups,
      source.index,
      destination.index
    );
  else {
    orderedMethodology.inspectionLayout?.inspectionGroups?.forEach(inspectionGroup => {
      if (inspectionGroup.id === id && inspectionGroup.categories) {
        inspectionGroup.categories = reorder(inspectionGroup.categories, source.index, destination.index);
      } else if (inspectionGroup.extraDimensions && inspectionGroup.extraDimensions.id === id) {
        inspectionGroup.extraDimensions.components = reorder(inspectionGroup.extraDimensions.components, source.index, destination.index);
      } else {
        inspectionGroup.categories?.forEach(category => {
          if (category.id === id && category.phenomenons) {
            category.phenomenons = reorder(category.phenomenons, source.index, destination.index);
          } else {
            category.phenomenons?.forEach(phenomenon => {
              if (phenomenon.id === id && phenomenon.components) {
                phenomenon.components = reorder(phenomenon.components, source.index, destination.index);
              }
            });
          }
        });
      }
    });
  }
  return orderedMethodology;
}

export const reorderMethodologyDeep = (methodologyDeep: MethodologyDeep, { id, source, destination }) => {
  return getOrderedMethodologyDeep(cloneDeep(methodologyDeep), {
    id,
    source,
    destination
  });
};

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((item, index) => {
    item.priority = index;
    if (item.characteristic && item.characteristic.priority !== undefined) item.characteristic.priority = index;
    if (item.indicator && item.indicator.priority !== undefined) item.indicator.priority = index;
  });
  return result;
};

export const getUpdatedMethodology = (methodologyDeep: MethodologyDeep, { id, collapsed }): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;
  const updatedMethodology = cloneDeep(methodologyDeep);
  updatedMethodology.inspectionLayout?.inspectionGroups?.forEach(inspectionGroup => {
    if (inspectionGroup.id === id) {
      inspectionGroup.collapsed = collapsed;
    } else if (inspectionGroup && inspectionGroup.extraDimensions && inspectionGroup.extraDimensions.id === id) {
      inspectionGroup.extraDimensions.collapsed = collapsed;
    } else if (inspectionGroup && inspectionGroup.categories) {
      inspectionGroup.categories.forEach(category => {
        if (category.id === id) {
          category.collapsed = collapsed;
        } else if (category.phenomenons) {
          category.phenomenons.forEach(phenomenon => {
            if (phenomenon.id === id) {
              phenomenon.collapsed = collapsed;
            }
          });
        }
      });
    }
  });
  return updatedMethodology;
};

export const toggleDefaultMethodology = (methodologyDeep: MethodologyDeep): MethodologyDeep => {
  const updatedMethodology = cloneDeep(methodologyDeep);
  updatedMethodology.defaultMethodology = !updatedMethodology.defaultMethodology;
  return updatedMethodology;
};

export const deletePhenomenon = (id: UUID, methodology: MethodologyDeep) => {
  if (methodology.inspectionLayout) {
    methodology.inspectionLayout.inspectionGroups.forEach(inspectionGroup => {
      inspectionGroup.categories?.forEach(category => {
        category.phenomenons?.forEach((phenomenon, i) => {
          if (category.phenomenons && phenomenon.id === id) {
            category.phenomenons.splice(i, 1);
            category.phenomenons.forEach((item, idx) => {
              item.priority = idx;
            });
          }
        });
      });
    });
  }
  return methodology;
};

export const checkPhenomenonMPI = (phenomenonId: UUID, methodology: MethodologyDeep): IndicatorDeep[] => {
  const indicators: IndicatorDeep[] = [];
  methodology.multiPhenomenaIndicators?.forEach(mpi => {
    mpi.phenomenonIds?.forEach(mpiPhenomenonId => {
      if (phenomenonId === mpiPhenomenonId) {
        indicators.push(mpi);
      }
    });
  });
  return indicators;
};

export function buildWrongIndicatorDeep(indicator: Indicator, orderedComponentId?: UUID): IndicatorDeep {
  const indicatorDeep: IndicatorDeep = buildIndicatorDeep(indicator, orderedComponentId);
  return {
    ...indicatorDeep,
    showOnInspectionLayout: false,
    wrongConfiguration: true
  };
}

export function buildIndicatorDeep(
  indicator: Indicator,
  orderedComponentId: UUID | undefined,
  inspectionLayoutShow?: boolean,
  expression = false
): IndicatorDeep {
  const indicatorDeep: IndicatorDeep = {
    id: orderedComponentId || uuid(),
    indicatorId: indicator.id as UUID,
    name: indicator.name as any,
    showOnInspectionLayout: inspectionLayoutShow || false,
    showOnTimeline: indicator.feature_flags ? !indicator.feature_flags.includes(FeatureFlagEnum.NO_TIMELINE) : false,
    showOnHeatmap: indicator.feature_flags ? !indicator.feature_flags.includes(FeatureFlagEnum.NO_HEATMAP) : false,
    diagnostics: indicator.diagnostics || [],
    characteristicsIds: indicator.expression?.characteristics ?? [indicator.characteristic_id],
    comesFromCanonical: isObjectCanonical(indicator),
    isDefault: Boolean(indicator.default),
    phenomenonIds: indicator.phenomenon_ids
  };
  if (expression) {
    indicatorDeep.expression = indicator.expression;
  }
  return indicatorDeep;
}

export function buildCharacteristicDeep(
  characteristic: Characteristic,
  orderedComponentId: UUID | undefined,
  input_definition = false
): CharacteristicDeep {
  const characteristicDeep: CharacteristicDeep = {
    id: orderedComponentId || uuid(),
    characteristicId: characteristic.id as UUID,
    aliasesIds: characteristic.aliases_ids,
    name: characteristic.name,
    comesFromCanonical: isObjectCanonical(characteristic)
  };

  if (input_definition) {
    characteristicDeep.input_definition = characteristic.input_definition;
  }
  return characteristicDeep;
}

export function updateIndicatorOnMethodologyDeep(methodologyDeep: MethodologyDeep, indicatorDeep: IndicatorDeep) {
  if (isSingleIndicator(indicatorDeep)) {
    const independeVariables = methodologyDeep.inspectionLayout?.independentVariables
      ? methodologyDeep.inspectionLayout?.independentVariables.map(iv => iv.id)
      : [];
    const inspectionGroups =
      methodologyDeep.inspectionLayout?.inspectionGroups?.map(ig => {
        const updatedInspectionGroup: InspectionGroupDeep = {
          ...ig,
          categories: ig.categories?.map(categoryDeep => {
            return {
              ...categoryDeep,
              phenomenons: categoryDeep.phenomenons?.map(phenomenonDeep => {
                if (phenomenonDeep.phenomenonId === indicatorDeep.phenomenonIds![0]) {
                  let components = phenomenonDeep.components || [];
                  let characteristicIds = components.filter(c => c.characteristic).map(c => c.characteristic!.characteristicId);
                  if (independeVariables.length) {
                    characteristicIds = [...characteristicIds, ...independeVariables] as string[];
                  }
                  if (!isWrongIndicatorDeep(indicatorDeep, characteristicIds)) {
                    const mutableComponents = components.filter(
                      c => c.characteristic || c.indicator!.indicatorId !== indicatorDeep.indicatorId
                    );
                    mutableComponents.push(buildComponentDeep(undefined, indicatorDeep));
                    components = mutableComponents;
                  }
                  return {
                    ...phenomenonDeep,
                    components
                  };
                }
                return phenomenonDeep;
              })
            };
          })
        };
        return updatedInspectionGroup;
      }) ?? [];
    const updatedMethodologyDeep: MethodologyDeep = {
      ...methodologyDeep,
      inspectionLayout: {
        ...methodologyDeep.inspectionLayout!,
        inspectionGroups
      }
    };
    return updatedMethodologyDeep;
  }
  if (isMultPhenonIndicator(indicatorDeep)) {
    const methodology = insertMultPhenonOnMethodologyDeep(methodologyDeep, indicatorDeep);
    return methodology;
  }

  throw new Error('There are not phenomenons in this indicator!');
}

export function insertMultPhenonOnMethodologyDeep(methodology: MethodologyDeep, indicatorDeep: IndicatorDeep) {
  if (indicatorDeep.phenomenonIds && indicatorDeep.phenomenonIds.length > 1) {
    const multPhenonIndicators: IndicatorDeep[] =
      methodology.multiPhenomenaIndicators?.map(mp => (mp.indicatorId === indicatorDeep.indicatorId ? indicatorDeep : mp)) ?? [];

    const mutableMultPhenon = multPhenonIndicators.find(mp => mp.indicatorId === indicatorDeep.indicatorId)
      ? multPhenonIndicators
      : [...multPhenonIndicators, indicatorDeep];

    return {
      ...methodology,
      multiPhenomenaIndicators: mutableMultPhenon
    };
  }
  throw new Error('Indicator not is Mult phenomenon!');
}

export function addOrUpdateInMethodologyDeepByCharacteristic(
  characteristic: Characteristic,
  defaultIndicator: Indicator,
  methodologyDeep: MethodologyDeep,
  orderedComponentId?: UUID,
  orderedPhenomenonId?: UUID
) {
  if (characteristic && characteristic.phenomenon_id) {
    let indicatorDeep;

    if (defaultIndicator) {
      indicatorDeep = buildIndicatorDeep(defaultIndicator, undefined);
    }
    const characteristicDeep = buildCharacteristicDeep(characteristic, orderedComponentId);
    const newComponent = getComponentDeepWithProperties(indicatorDeep, characteristicDeep, 0);

    if (orderedPhenomenonId && !orderedComponentId) {
      return addComponentToPhenomenonDeep(methodologyDeep, orderedPhenomenonId, newComponent);
    }
    return updateCharacteristicInMethodologyDeep(methodologyDeep, newComponent);
  }

  return methodologyDeep;
}

export function updateCharacteristicInMethodologyDeep(methodologyDeep: MethodologyDeep, componentDeep: ComponentDeep) {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;

  const mutableMethodology = cloneDeep(methodologyDeep);

  mutableMethodology.inspectionLayout?.inspectionGroups?.forEach(ig => {
    ig.categories?.forEach(categoryDeep => {
      if (categoryDeep.phenomenons)
        categoryDeep.phenomenons.forEach(phenomenonDeep => {
          if (phenomenonDeep.components)
            phenomenonDeep.components.forEach((component, index) => {
              if (
                component.characteristic &&
                componentDeep.characteristic &&
                component.characteristic.characteristicId === componentDeep.characteristic.characteristicId
              ) {
                phenomenonDeep.components![index] = componentDeep;
                updatePriorities(phenomenonDeep.components);
              }
            });
        });
    });
  });
  return mutableMethodology;
}

function updatePriorities(list: any) {
  list.forEach((item, index) => (item.priority = index));
  return list;
}

function inspectionGroupsExist(methodology: MethodologyDeep) {
  return methodology && methodology.inspectionLayout && methodology.inspectionLayout.inspectionGroups;
}

export const addCategoryPhenomenaToMethodology = (
  methodologyDeep: MethodologyDeep,
  inspectionGroupId,
  categories: CategoryDeep[]
): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;

  const mutableMethodology = cloneDeep(methodologyDeep);

  const inspectionGroupToBeUpdated = getObjectById(mutableMethodology.inspectionLayout?.inspectionGroups ?? [], inspectionGroupId);

  if (inspectionGroupToBeUpdated)
    categories.forEach(category => {
      if (category.phenomenons) {
        if (inspectionGroupToBeUpdated.categories && inspectionGroupToBeUpdated.categories.length > 0) {
          const categoryIndex = inspectionGroupToBeUpdated.categories.findIndex(
            metCategory => metCategory.categoryId === category.categoryId
          );
          if (categoryIndex !== -1) {
            const { phenomenons } = inspectionGroupToBeUpdated.categories[categoryIndex];
            const phenomenonIdsInMethodology = phenomenons?.map(phenomenon => phenomenon.phenomenonId) ?? [];

            category.phenomenons.forEach(phenomenonToBeAdded => {
              if (!phenomenonIdsInMethodology.includes(phenomenonToBeAdded.phenomenonId)) phenomenons?.push(phenomenonToBeAdded);
            });

            updatePriorities(phenomenons);
          } else {
            inspectionGroupToBeUpdated.categories.push(category);
            inspectionGroupToBeUpdated.categories = updatePriorities(inspectionGroupToBeUpdated.categories);
          }
        } else {
          inspectionGroupToBeUpdated.categories = [category];
          inspectionGroupToBeUpdated.categories = updatePriorities(inspectionGroupToBeUpdated.categories);
        }
      }
    });

  return mutableMethodology;
};

export const addExtraDimensionToMethodology = (
  methodologyDeep: MethodologyDeep,
  inspectionGroupId,
  extraDimensions: ExtraDimensionsDeep[]
): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;
  const mutableMethodology = cloneDeep(methodologyDeep);
  const inspectionGroupToBeUpdated = getObjectById(mutableMethodology.inspectionLayout?.inspectionGroups ?? [], inspectionGroupId);
  if (inspectionGroupToBeUpdated) {
    extraDimensions.forEach(extraDimension => {
      if (extraDimension) {
        if (inspectionGroupToBeUpdated && inspectionGroupToBeUpdated.extraDimensions) {
          inspectionGroupToBeUpdated.extraDimensions.components.push(extraDimension);
          updatePriorities(inspectionGroupToBeUpdated.extraDimensions.components);
        }
      }
    });
  }
  return mutableMethodology;
};

export const editExtraDimensionToMethodology = (methodologyDeep: MethodologyDeep, extraDimensionId, extraDimension): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;
  const mutableMethodology = cloneDeep(methodologyDeep);
  mutableMethodology.inspectionLayout?.inspectionGroups.forEach(ig => {
    if (!ig.extraDimensions) return;
    const idx = ig.extraDimensions.components.findIndex(ex => ex.id === extraDimensionId);
    if (idx !== -1) {
      ig.extraDimensions.components[idx] = { ...extraDimension, priority: 0 };
      updatePriorities(ig.extraDimensions.components);
    }
  });
  return mutableMethodology;
};

export const removePhenomenonFromMethodology = (methodologyDeep: MethodologyDeep, phenomenonId: UUID): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;

  const mutableMethodology = cloneDeep(methodologyDeep);
  let updateListVariableValidation = mutableMethodology?.variablesValidation;

  mutableMethodology.inspectionLayout?.inspectionGroups.forEach(inspectionGroup => {
    if (inspectionGroup.categories) {
      inspectionGroup.categories.forEach((category, categoryIndex) => {
        if (category.phenomenons) {
          const phenomenonIndex = category.phenomenons.findIndex(phenomenon => phenomenon.id === phenomenonId);

          if (phenomenonIndex !== -1) {
            if (updateListVariableValidation && updateListVariableValidation.length !== 0) {
              category.phenomenons.forEach(phenomeno => {
                phenomeno?.components?.forEach(component => {
                  updateListVariableValidation = removeVariableValidationByCharacteristicId(
                    updateListVariableValidation,
                    component.characteristic?.characteristicId
                  );
                });
              });
            }

            if (category.phenomenons.length === 1) {
              inspectionGroup.categories?.splice(categoryIndex, 1);
              updatePriorities(inspectionGroup.categories);
            } else {
              category.phenomenons.splice(phenomenonIndex, 1);
              updatePriorities(category.phenomenons);
            }
          }
        }
      });
    }
  });

  mutableMethodology.variablesValidation = updateListVariableValidation;

  return mutableMethodology;
};

export const removeExtraDimensionFromMethodology = (
  methodologyDeep: MethodologyDeep,
  extraDimensionId: UUID,
  inspectionGroupId: UUID
): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;
  const mutableMethodology = cloneDeep(methodologyDeep);
  mutableMethodology.inspectionLayout?.inspectionGroups.forEach(inspectionGroup => {
    if (inspectionGroup.id === inspectionGroupId && inspectionGroup.extraDimensions) {
      inspectionGroup.extraDimensions.components = inspectionGroup.extraDimensions.components.filter(ex => ex.id !== extraDimensionId);
      updatePriorities(inspectionGroup.extraDimensions.components);
    }
  });
  return mutableMethodology;
};

export const createCategoryWithPhenomena = (phenomenaIds: any, phenomenonTree: Phenomenon[]): CategoryDeep[] => {
  function validateParameters() {
    phenomenaIds.forEach(phenomenonId => {
      if (!findObjectById(phenomenonTree, phenomenonId))
        throw new Error(`Phenomenon tree supplied does not contain phenomenon with id ${phenomenonId}`);
    });
  }

  validateParameters();

  return phenomenaIds.map(phenomenonId => {
    const phenomenonTreePhenomenon = phenomenonTree.find(p => p.id === phenomenonId);
    if (phenomenonTreePhenomenon) {
      return createCategoryWithPhenomenaFromPhenomenaTree(phenomenonTreePhenomenon);
    }
  });
};

function createCategoryWithPhenomenaFromPhenomenaTree(phenomenonTreePhenomenon: Phenomenon): CategoryDeep {
  return {
    id: uuid(),
    categoryId: phenomenonTreePhenomenon.category!.id!,
    name: phenomenonTreePhenomenon.category!.name,
    priority: 0,
    phenomenons: [createPhenomenonFromPhenomenaTree(phenomenonTreePhenomenon)],
    comesFromCanonical: isObjectCanonical(phenomenonTreePhenomenon)
  };
}

const buildNewCategoryDeepFromPhenomenon = (phenomenonSet: PhenomenonSet, priority: number): CategoryDeep => {
  return {
    ...createCategoryWithPriorityFromPhenomenaTree(phenomenonSet.phenomenon, priority),
    phenomenons: [phenomenonSet.phenomenonDeep]
  };
};

export function createCategoryWithPriorityFromPhenomenaTree(phenomenon: Phenomenon, priority: number): CategoryDeep {
  return {
    id: uuid(),
    categoryId: phenomenon.category!.id!,
    name: phenomenon.category!.name,
    priority,
    phenomenons: [],
    comesFromCanonical: isObjectCanonical(phenomenon.category)
  };
}

function createPhenomenonFromPhenomenaTree(phenomenonTreePhenomenon: Phenomenon): PhenomenonDeep {
  return {
    id: uuid(),
    phenomenonId: phenomenonTreePhenomenon.id!,
    name: phenomenonTreePhenomenon.name,
    scientificName: phenomenonTreePhenomenon.short_description,
    comesFromCanonical: isObjectCanonical(phenomenonTreePhenomenon),
    components: []
  };
}

export const upsertInspectionGroup = (methodologyDeep: MethodologyDeep, { inspectionGroupName, inspectionGroupId }): MethodologyDeep => {
  const updatedMethodology = cloneDeep(methodologyDeep);

  const inspectionGroupIndex =
    updatedMethodology.inspectionLayout && updatedMethodology.inspectionLayout.inspectionGroups
      ? updatedMethodology.inspectionLayout.inspectionGroups.findIndex(inspectionGroup => inspectionGroup.id === inspectionGroupId)
      : -1;

  const localizedName = {
    localized_strings: {
      en: inspectionGroupName
    }
  };

  if (inspectionGroupIndex !== -1) {
    updatedMethodology.inspectionLayout!.inspectionGroups[inspectionGroupIndex].name = { ...localizedName };
  } else {
    let inspectionLayout;
    if (updatedMethodology.inspectionLayout && updatedMethodology.inspectionLayout.inspectionGroups.length > 0) {
      inspectionLayout = updatedMethodology.inspectionLayout;
      inspectionLayout.inspectionGroups.push(createEmptyInspectionGroupDeep(localizedName));
    } else {
      inspectionLayout = createEmptyInspectionLayoutDeep(
        localizedName,
        updatedMethodology && updatedMethodology.inspectionLayout && updatedMethodology.inspectionLayout!.independentVariables
      );
    }

    updatedMethodology.inspectionLayout = inspectionLayout;
  }

  updatePriorities(updatedMethodology.inspectionLayout!.inspectionGroups);

  return updatedMethodology;
};

const createEmptyInspectionGroupDeep = (inspectionGroupName: I18nMap) => {
  return {
    id: uuid(),
    extraDimensions: {
      id: uuid(),
      components: []
    },
    name: { ...inspectionGroupName },
    inspectionGroupId: uuid(),
    priority: 0,
    collapsed: false
  };
};

function createEmptyInspectionLayoutDeep(inspectionGroupName: I18nMap, independentVariables?: IndependentVariable[]) {
  const inspectionGroup: InspectionGroupDeep = createEmptyInspectionGroupDeep(inspectionGroupName);

  const inspectionLayout: InspectionLayoutDeep = {
    id: uuid(),
    inspectionGroups: [{ ...inspectionGroup }],
    independentVariables: independentVariables?.length ? [...independentVariables] : []
  };
  return inspectionLayout;
}

export const mapCompanyPhenomena = (phenomenonsTree: ReadonlyArray<Phenomenon | PhenomenonSingle>): Phenomena[] => {
  return phenomenonsTree.map(phenomenon => {
    return {
      id: phenomenon.id ? phenomenon.id : uuid(),
      isCanonic: isObjectCanonical(phenomenon),
      name: getValueLanguage(phenomenon.name),
      scientificName: getValueLanguage(phenomenon.short_description),
      category: phenomenon.category ? getValueLanguage(phenomenon.category.name) : ''
    };
  });
};

export const deleteInspectionGroup = (methodologyDeep: MethodologyDeep, inspectionGroupId: UUID): MethodologyDeep => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;
  const updatedMethodology: MethodologyDeep = cloneDeep(methodologyDeep);
  remove(updatedMethodology.inspectionLayout!.inspectionGroups, (inspG: InspectionGroupDeep) => inspG.id === inspectionGroupId);

  return updatedMethodology;
};

export const getBasicInfoFromMethodologyDeep = (methodologyDeep: MethodologyDeep) => {
  return {
    name: getValueLanguage(methodologyDeep.name),
    company: methodologyDeep.company && methodologyDeep.company.id,
    crop: methodologyDeep.crop && methodologyDeep.crop.id,
    description: getValueLanguage(methodologyDeep.description),
    seasons: mapSeasonToBasic(methodologyDeep.seasonsTree),
    modifiedAt: methodologyDeep && methodologyDeep.modified_at
  };
};

export const getBasicInfoFromMethodology = (methodology: Methodology) => {
  return {
    name: getValueLanguage(methodology.name),
    company: methodology.company_id,
    crop: methodology.crop_id,
    description: getValueLanguage(methodology.description),
    modifiedAt: methodology.modified_at
  };
};

const mapSeasonToBasic = seasonsTree => {
  const result: any = [];
  if (seasonsTree && seasonsTree.seasons && seasonsTree.seasonAreas && seasonsTree.seasonProperties) {
    seasonsTree.seasonAreas.map((e: SeasonDeep) => {
      result.push(e.id);
    });
    seasonsTree.seasonProperties.map((e: SeasonDeep) => {
      result.push(e.id);
    }) &&
      seasonsTree.seasons.map((e: SeasonDeep) => {
        result.push(e.id);
      });
  }
  return result;
};

export const createComponentDeepFromPhenomenaTree = (phenomenaTree: Phenomenon[]): ComponentDeep[] => {
  const componentDeepArray: ComponentDeep[] = [];

  return phenomenaTree.reduce((previous, current) => {
    if (!previous || previous.length === 0) return [...createComponentDeepFromPhenomenonTree(current)];
    previous.push(...createComponentDeepFromPhenomenonTree(current));
    return previous;
  }, componentDeepArray);
};

export const createComponentDeepFromPhenomenonTree = (phenomenonTree: Partial<Phenomenon>): ComponentDeep[] => {
  const componentDeep: ComponentDeep[] = [];
  let indicatorDeep: IndicatorDeep | undefined;
  let characteristicDeep: CharacteristicDeep;
  const defaultIndicatorsIds: string[] = [];

  if (phenomenonTree?.characteristics) {
    phenomenonTree.characteristics.forEach(phenomenonCharacteristic => {
      indicatorDeep = undefined;
      characteristicDeep = buildCharacteristicDeep(phenomenonCharacteristic, undefined, true);

      if (phenomenonCharacteristic.default_indicator_id) {
        defaultIndicatorsIds.push(phenomenonCharacteristic.default_indicator_id);
        const defaultIndicator = phenomenonTree.indicators?.find(
          indicator => indicator.id === phenomenonCharacteristic.default_indicator_id
        );
        if (defaultIndicator) indicatorDeep = buildIndicatorDeep(defaultIndicator, undefined, undefined, true);
      }

      componentDeep.push({
        characteristic: characteristicDeep,
        indicator: indicatorDeep
      });
    });
  }

  if (!phenomenonTree?.indicators) return componentDeep;

  phenomenonTree.indicators.forEach(indicator => {
    if (!indicator.phenomenon_ids) {
      throw new Error('Some indicator dont have phenomenons associate.');
    }
    if (indicator.id && !defaultIndicatorsIds.includes(indicator.id) && indicator.phenomenon_ids.length === 1) {
      const onlyIndicatorDeep = buildIndicatorDeep(indicator, undefined, undefined, true);
      componentDeep.push({ indicator: onlyIndicatorDeep });
    }
  });

  return componentDeep;
};

export function getCharacteristicsIdsByMethodologyDeep(methodologyDeep?: MethodologyDeep) {
  if (!methodologyDeep) return [];
  const inspectionLayout: InspectionLayout | undefined = convertFromInspectionLayoutDeep(methodologyDeep.inspectionLayout);
  if (inspectionLayout) {
    const independentVariablesIds =
      (inspectionLayout.independent_variables && inspectionLayout.independent_variables.map(iv => iv.component_id!)) || [];
    const variablesIds = [...inspectionLayout.characteristic_ids, ...independentVariablesIds];
    return variablesIds;
  }
  return [];
}

export const removeByIndicatorId = (list: any, indicatorId: UUID) => {
  return list ? list.filter(i => i.indicatorId !== indicatorId) : [];
};

const getWrongIndicatorsByMethodology = (wrongIndicators: IndicatorDeep[], characteristicsOnMethodology: UUID[]) => {
  return wrongIndicators.filter(i => isWrongIndicatorDeep(i, characteristicsOnMethodology));
};

export function insertComponentsOnMethodologyDeep(
  methodologyDeep: MethodologyDeep,
  orderedPhenomenonId: UUID,
  componentsToAdd: ComponentDeep[]
) {
  const characteristicsOnMethodology = [
    ...componentsToAdd.filter(c => c.characteristic).map(c => c.characteristic!.characteristicId),
    ...getCharacteristicsIdsByMethodologyDeep(methodologyDeep)
  ];

  const wrongComponents = componentsToAdd
    .filter(c => !c.characteristic && c.indicator && isWrongIndicatorDeep(c.indicator, characteristicsOnMethodology))
    .map(i => ({ ...i.indicator!, wrongConfiguration: true }));

  const methodologyWrongIndicators = methodologyDeep.wrongIndicators || [];
  const wrongIndicatorDeeps = getWrongIndicatorsByMethodology(
    [...methodologyWrongIndicators, ...wrongComponents],
    characteristicsOnMethodology
  );

  const componentsWrongToAdd = methodologyWrongIndicators
    .filter(i => !wrongIndicatorDeeps.map(deep => deep.indicatorId).includes(i.indicatorId))
    .map(i => getComponentDeepWithProperties({ ...i, wrongConfiguration: false }));

  componentsToAdd.push(...componentsWrongToAdd);

  const componentsWithCharacteristicsFound = componentsToAdd.filter(
    c => c.characteristic || (c.indicator && !isWrongIndicatorDeep(c.indicator, characteristicsOnMethodology))
  );

  const mutableMethodologyDeep: MethodologyDeep = {
    ...methodologyDeep,
    inspectionLayout: {
      ...methodologyDeep.inspectionLayout!,
      inspectionGroups:
        methodologyDeep.inspectionLayout?.inspectionGroups?.map(ig => {
          return {
            ...ig,
            categories:
              ig.categories &&
              ig.categories.map(c => {
                return {
                  ...c,
                  phenomenons:
                    c.phenomenons &&
                    c.phenomenons.map(p => {
                      if (p.id === orderedPhenomenonId) {
                        return {
                          ...p,
                          components: updatePriorities([...p.components, ...componentsWithCharacteristicsFound])
                        };
                      }
                      return p;
                    })
                };
              })
          };
        }) ?? []
    },
    wrongIndicators: wrongIndicatorDeeps
  };

  return mutableMethodologyDeep;
}

export function removeMultPhenonIndicatorMethodologyDeep(methodologyDeep: MethodologyDeep, indicatorId: UUID): MethodologyDeep {
  if (!methodologyDeep.multiPhenomenaIndicators) {
    throw new Error('This methodology dont have mult phenon indicators in analytic context!');
  }
  return {
    ...methodologyDeep,
    multiPhenomenaIndicators: methodologyDeep.multiPhenomenaIndicators.filter(mp => mp.indicatorId !== indicatorId)
  };
}
export const addComponentToPhenomenonDeep = (methodologyDeep: MethodologyDeep, orderedPhenomenonId: UUID, componentDeep: ComponentDeep) => {
  if (!inspectionGroupsExist(methodologyDeep)) return methodologyDeep;

  const updatedMethodology = cloneDeep(methodologyDeep);
  updatedMethodology.inspectionLayout?.inspectionGroups?.forEach(inspectionGroup => {
    if (inspectionGroup.categories) {
      inspectionGroup.categories.forEach(category => {
        if (category.phenomenons) {
          category.phenomenons.forEach(phenomenon => {
            if (phenomenon.id === orderedPhenomenonId) {
              if (phenomenon.components) phenomenon.components.push(componentDeep);
              else phenomenon.components = [componentDeep];

              updatePriorities(phenomenon.components);
            }
          });
        }
      });
    }
  });

  return updatedMethodology;
};

export const toggleShowFlag = (methodology: MethodologyDeep, indicatorId: UUID, type: 'timeline' | 'inspLayout'): MethodologyDeep => {
  const updatedMethodology: MethodologyDeep = cloneDeep(methodology);
  let hasFoundInMultiphenom = false;

  const multiPhenIndicator = methodology.multiPhenomenaIndicators?.map(indi => {
    if (indi.indicatorId !== indicatorId) {
      return indi;
    }

    if (type === 'timeline') {
      hasFoundInMultiphenom = true;
      return {
        ...indi,
        showOnTimeline: !indi.showOnTimeline
      };
    }
    hasFoundInMultiphenom = true;
    return {
      ...indi,
      showOnInspectionLayout: !indi.showOnInspectionLayout
    };
  });

  if (hasFoundInMultiphenom) {
    updatedMethodology.multiPhenomenaIndicators = multiPhenIndicator;
  } else {
    updatedMethodology?.inspectionLayout?.inspectionGroups.forEach(inspGroupDeep => {
      inspGroupDeep.categories?.forEach(categoryDeep => {
        categoryDeep.phenomenons?.forEach(phenDeep => {
          phenDeep.components?.forEach(compDeep => {
            if (compDeep.indicator) {
              if (compDeep.indicator.indicatorId === indicatorId) {
                if (type === 'timeline') {
                  compDeep.indicator.showOnTimeline = !compDeep.indicator.showOnTimeline;
                } else {
                  compDeep.indicator.showOnInspectionLayout = !compDeep.indicator.showOnInspectionLayout;
                }
              }
            }
          });
        });
      });
    });
  }

  return updatedMethodology;
};

export const checkIfCharacteristicIsUsedAndRemoveFromMethodology = (
  methodologyDeep: MethodologyDeep,
  component: SimpleCharacteristicsIndicatorsProps,
  flagPreventIndicatorDuplicity?: boolean
) => {
  const mutableMethodology = cloneDeep(methodologyDeep);
  let indicatorsThatReferenceCharacteristic: string[] = [];
  if (mutableMethodology.multiPhenomenaIndicators) {
    indicatorsThatReferenceCharacteristic = getIndicatorsThatContainCharacteristicReference(
      mutableMethodology.multiPhenomenaIndicators,
      component.characteristicId!
    );
  }

  if (mutableMethodology.inspectionLayout && mutableMethodology.inspectionLayout.inspectionGroups) {
    mutableMethodology.inspectionLayout.inspectionGroups.forEach(inspectionGroup => {
      if (inspectionGroup.categories) {
        inspectionGroup.categories.forEach(category => {
          if (category.phenomenons) {
            category.phenomenons.forEach(phenomenon => {
              if (phenomenon.phenomenonId === component.phenomenonId && phenomenon.components) {
                const indicatorsInInspectionLayoutThatReferenceCharacteristic = checkIfCharacteristicIsUsedAnotherComponent(
                  phenomenon.components,
                  component.characteristicId!
                );

                if (
                  indicatorsInInspectionLayoutThatReferenceCharacteristic &&
                  indicatorsInInspectionLayoutThatReferenceCharacteristic.length > 0
                ) {
                  indicatorsThatReferenceCharacteristic.push(...indicatorsInInspectionLayoutThatReferenceCharacteristic);
                }
                if (indicatorsThatReferenceCharacteristic.length === 0) {
                  phenomenon.components = removeComponentByCharacteristicIdAndIndicatorId(
                    phenomenon.components,
                    component.characteristicId,
                    component.indicatorId,
                    component.id,
                    flagPreventIndicatorDuplicity
                  );
                }
              }
            });
          }
        });
      }
    });
  }

  mutableMethodology.variablesValidation = removeVariableValidationByCharacteristicId(
    mutableMethodology.variablesValidation,
    component.characteristicId
  );

  mutableMethodology.wrongIndicators = removeByIndicatorId(mutableMethodology.wrongIndicators, component.indicatorId!);

  return {
    mutableMethodology,
    indicatorsThatReferenceCharacteristic
  };
};

export function removeVariableValidationByCharacteristicId(variableValidations?: VariableValidation[], characteristicId?: string) {
  if (!variableValidations || variableValidations.length === 0 || !characteristicId) {
    return variableValidations;
  }

  const updateList = variableValidations.filter(item => !JSON.stringify(item).includes(characteristicId));
  return updateList;
}

function getIndicatorsThatContainCharacteristicReference(indicators: IndicatorDeep[], characteristicId: UUID) {
  const indicatorsNames: string[] = [];

  indicators.forEach(indicator => {
    if (indicator.characteristicsIds && indicator.characteristicsIds.includes(characteristicId)) {
      indicatorsNames.push(getValueLanguage(indicator.name));
    }
  });
  return indicatorsNames;
}

export function filterCanonicalPhenomenonDuplicates(companyAndCanonicalPhenomena: Phenomenon[], companyId: UUID) {
  const companyPhenomena = companyAndCanonicalPhenomena.filter(phenomenon => phenomenon.company_id === companyId);

  const canonicalReferencePhenomena: Phenomenon[] = companyAndCanonicalPhenomena.filter(
    phenomenon => isObjectCanonical(phenomenon) && !phenomenon.company_id
  );

  const companyReferencedPhenomenaIds: UUID[] = companyPhenomena
    .filter(phenomenon => !!phenomenon.parent_id)
    .map(phenomenon => phenomenon.parent_id!);

  const canonicalNotInCompany: Phenomenon[] = canonicalReferencePhenomena.filter(
    canonicalReference => canonicalReference.id && !companyReferencedPhenomenaIds.includes(canonicalReference.id)
  );

  return [...companyPhenomena, ...canonicalNotInCompany];
}

function addCanonicalNotInCompany(canonicals, derivedCanonicals) {
  const availableForCompany = cloneDeep(derivedCanonicals);
  if (canonicals && canonicals.length > 0) {
    if (!availableForCompany || availableForCompany.length === 0) return canonicals;

    const derivedReferencedIds = availableForCompany ? availableForCompany.map(object => object.parent_id).filter(id => id !== null) : [];

    const objectsNotInDerivedPhenomenon = canonicals.filter(object => !derivedReferencedIds.includes(object.id));

    availableForCompany.push(...objectsNotInDerivedPhenomenon);
  }
  return availableForCompany;
}

export function addCanonicalComponentsAvailableInPhenomenon(phenomenaTree: Phenomenon[], phenomenon) {
  if (!phenomenon.parent_id) return phenomenon;

  const canonicalReferencePhenomenon: Phenomenon = findObjectById(phenomenaTree, phenomenon.parent_id);
  const phenomenonWithCanonicals = cloneDeep(phenomenon);

  if (canonicalReferencePhenomenon) {
    const { indicators, characteristics } = canonicalReferencePhenomenon;
    if (characteristics) {
      phenomenonWithCanonicals.characteristics = addCanonicalNotInCompany(characteristics, phenomenonWithCanonicals.characteristics);
    }

    if (indicators) {
      phenomenonWithCanonicals.indicators = addCanonicalNotInCompany(indicators, phenomenonWithCanonicals.indicators);
    }
  }
  return phenomenonWithCanonicals;
}

export function getPhenomenaDeepFromMethodologyDeep(methodologyDeep: MethodologyDeep) {
  const methodologyPhenomena =
    methodologyDeep?.inspectionLayout?.inspectionGroups
      ?.flatMap(inspectionGroup => inspectionGroup.categories)
      ?.filter(category => !isEmpty(category?.phenomenons))
      .flatMap(category => category?.phenomenons ?? []) ?? [];

  return methodologyPhenomena;
}

export function getPhenomenaMapFromMethodologyDeep(methodologyDeep: MethodologyDeep): Map<string, PhenomenonDeep> {
  const methodologyPhenomena = getPhenomenaDeepFromMethodologyDeep(methodologyDeep);

  return new Map<string, PhenomenonDeep>(methodologyPhenomena.map(phenomenon => [phenomenon.phenomenonId, phenomenon]));
}

export function getMethodologyDeepComponentDeep(methodologyDeep: MethodologyDeep) {
  const wrongComponents = (methodologyDeep.wrongIndicators || []).map(i => getComponentDeepWithProperties(i));
  const methodologyPhenomena = getPhenomenaDeepFromMethodologyDeep(methodologyDeep);

  const inspectionLayoutComponents = methodologyPhenomena
    .filter(phenomenon => phenomenon && phenomenon.components && phenomenon.components.length > 0)
    .flatMap(phenomenon => phenomenon?.components ?? []);

  const independentVariables: ComponentDeep[] =
    methodologyDeep.inspectionLayout &&
    methodologyDeep.inspectionLayout.independentVariables &&
    methodologyDeep.inspectionLayout.independentVariables.length
      ? methodologyDeep.inspectionLayout.independentVariables.map(
          ({ aliases_ids, id, name }) =>
            ({
              characteristic: { id, characteristicId: id, name, ...(aliases_ids && { aliasesIds: aliases_ids }) } as CharacteristicDeep
            } as ComponentDeep)
        )
      : [];
  inspectionLayoutComponents.push(...independentVariables);

  return [...inspectionLayoutComponents, ...wrongComponents];
}

export function getCharacteristicIdsUsedInMethodology(componentDeeps: (ComponentDeep | undefined)[]) {
  if (!componentDeeps) return [];

  return componentDeeps.reduce<ReadonlyArray<UUID>>((acc, componentDeep) => {
    if (!componentDeep || !componentDeep.indicator || !componentDeep.indicator.characteristicsIds) return acc;
    return [...acc, componentDeep.indicator.characteristicsIds].flat();
  }, []);
}

export function getUsedIdsInMethodology(componentDeeps: (ComponentDeep | undefined)[]) {
  const usedCharacteristicIds: string[] =
    componentDeeps &&
    componentDeeps
      .filter(component => component && component.characteristic)
      .flatMap(component => component!.characteristic!.characteristicId);

  const usedCharacteristicIdsAsAliases =
    componentDeeps &&
    componentDeeps
      .filter(
        component =>
          component && component.characteristic && component.characteristic.aliasesIds && component.characteristic.aliasesIds.length
      )
      .flatMap(component => component!.characteristic!.aliasesIds || []);

  const usedIndicatorIds =
    componentDeeps &&
    componentDeeps.filter(component => component && component.indicator).flatMap(component => component!.indicator!.indicatorId);

  return { usedCharacteristicIds, usedCharacteristicIdsAsAliases, usedIndicatorIds };
}

function filterComponentsInMethodology(methodologyDeep: MethodologyDeep, selectedPhenomenon: Phenomenon) {
  if (
    !selectedPhenomenon ||
    !methodologyDeep ||
    !methodologyDeep.inspectionLayout ||
    !methodologyDeep.inspectionLayout.inspectionGroups ||
    methodologyDeep.inspectionLayout.inspectionGroups.length === 0
  )
    return selectedPhenomenon;

  const componentDeeps = getMethodologyDeepComponentDeep(methodologyDeep);

  const phenomenon = cloneDeep(selectedPhenomenon);

  const { usedCharacteristicIds, usedCharacteristicIdsAsAliases, usedIndicatorIds } = getUsedIdsInMethodology(componentDeeps);

  if (phenomenon.characteristics) {
    const usedCharacteristicsAndAliasesIds = [...usedCharacteristicIds, ...usedCharacteristicIdsAsAliases];
    phenomenon.characteristics = phenomenon.characteristics.filter(
      characteristic => !usedCharacteristicsAndAliasesIds.includes(characteristic.id!)
    );
  }

  if (phenomenon.indicators) phenomenon.indicators = phenomenon.indicators.filter(indicator => !usedIndicatorIds.includes(indicator.id!));

  return phenomenon;
}

function getAvailableComponentsFromPhenomenon(
  methodologyDeep: MethodologyDeep,
  selectedPhenomenonId: UUID,
  phenomenaTree: Phenomenon[]
): Phenomenon {
  const selectedPhenomenon = findObjectById(phenomenaTree, selectedPhenomenonId) as Phenomenon;
  if (!selectedPhenomenon) console.warn('Phenomenon id could not be found in phenomena tree', phenomenaTree);
  let newPhenomenon = cloneDeep(selectedPhenomenon);

  if (selectedPhenomenon && isObjectCanonical(selectedPhenomenon)) {
    newPhenomenon = addCanonicalComponentsAvailableInPhenomenon(phenomenaTree, selectedPhenomenon);
    newPhenomenon = filterDeletedObjsInCompanyPhenomenon(newPhenomenon, phenomenaTree, methodologyDeep);
  }

  return newPhenomenon;
}

export function getComponentsFromPhenomenon(methodologyDeep: MethodologyDeep, selectedPhenomenonId: UUID, phenomenaTree: Phenomenon[]) {
  let newPhenomenon = getAvailableComponentsFromPhenomenon(methodologyDeep, selectedPhenomenonId, phenomenaTree);

  newPhenomenon = filterComponentsInMethodology(methodologyDeep, newPhenomenon);

  return createComponentDeepFromPhenomenonTree(newPhenomenon);
}

export function getCanonicalIdsToClone(ids, objectsTree: Array<Phenomenon | Characteristic | Indicator>) {
  const idsToClone = new Set<UUID>();
  const treeMap = new Map<UUID, Phenomenon | Characteristic | Indicator>(objectsTree.map(obj => [obj.id!, obj]));

  ids.forEach(id => {
    const object = treeMap.get(id);
    if (object && isObjectReferenceCanonical(object)) {
      idsToClone.add(id);
    }
  });

  return Array.from(idsToClone);
}

export function filterDeletedObjsInCompanyPhenomenon(
  phenomenon: Phenomenon,
  phenomenaTree: Phenomenon[],
  methodologyDeep: MethodologyDeep
) {
  if (!phenomenon.parent_id) return phenomenon;

  const canonicalRefPhen: Phenomenon = findObjectById(phenomenaTree, phenomenon.parent_id);
  const updatedCompanyPhenomenon: Phenomenon = cloneDeep(phenomenon);

  if (canonicalRefPhen) {
    if (
      !methodologyDeep ||
      !methodologyDeep.inspectionLayout ||
      !methodologyDeep.inspectionLayout.inspectionGroups ||
      methodologyDeep.inspectionLayout.inspectionGroups.length === 0
    )
      return phenomenon;

    const componentDeeps = getMethodologyDeepComponentDeep(methodologyDeep);
    const { usedCharacteristicIds, usedIndicatorIds } = getUsedIdsInMethodology(componentDeeps);

    if (phenomenon.characteristics) {
      const canonicalCharacteristicIds: string[] = getIds(canonicalRefPhen.characteristics);
      const filteredCharac: Characteristic[] = [];
      phenomenon.characteristics.forEach(c => {
        if (
          !c.deleted_at &&
          ((c.parent_id && canonicalCharacteristicIds.includes(c.parent_id)) ||
            (c.id && usedCharacteristicIds.includes(c.id)) ||
            !isObjectCanonical(c) ||
            !c.company_id)
        )
          filteredCharac.push(c);
      });
      updatedCompanyPhenomenon.characteristics = filteredCharac;
    }

    if (phenomenon.indicators) {
      const canonicalIndicatorsIds: string[] = getIds(canonicalRefPhen.indicators);
      const filteredIndi: Indicator[] = [];
      phenomenon.indicators.forEach(i => {
        if (
          !i.deleted_at &&
          ((i.parent_id && canonicalIndicatorsIds.includes(i.parent_id)) ||
            (i.id && usedIndicatorIds.includes(i.id)) ||
            !isObjectCanonical(i) ||
            !i.company_id)
        )
          filteredIndi.push(i);
      });
      updatedCompanyPhenomenon.indicators = filteredIndi;
    }
  }

  return updatedCompanyPhenomenon;
}

export const checkIntegrityPhenomenaTree = (phenomenaTree: Phenomenon[]) => {
  const defaultExpressions: string[] = Object.values(DefaultExpressionType);
  const wrongDefaultIndicators = phenomenaTree.flatMap(p => {
    return (p.indicators || []).filter(
      i =>
        i.expression &&
        defaultExpressions.includes(i.expression.type!) &&
        i.expression.characteristics &&
        i.expression.characteristics.length > 1
    );
  });
  if (wrongDefaultIndicators.length) {
    message.warn('There are default indicators with problems on this organization. Check the console.');
    wrongDefaultIndicators.forEach(i =>
      console.warn(`Fix default indicador: ${window.location.origin}/${i.company_id}/indicator-edit/${i.id}`)
    );
  }
  return wrongDefaultIndicators;
};
