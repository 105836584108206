import React from 'react';
import { UserPermissions, PermissionScope } from '../../helpers/userAuth/userAuth';

import MethodologyIcon from '../../assets/icons/sidebar/MethodologyIcon';
import FixedPointsCategoriesIcon from '../../assets/icons/sidebar/FixedPointsCategoriesIcon';
import CanonicListsIcon from '../../assets/icons/sidebar/CanonicListsIcon';
import OrganizationIcon from '../../assets/icons/sidebar/OrganizationIcon';
import { FixedPointType } from '../../redux/models';

const { METHODOLOGY, FIXED_POINTS, CANONICAL_CROP, ADMIN_DEVICES } = UserPermissions;
const { READ } = PermissionScope;

const options = [
  {
    key: 'templates',
    label: 'sidebar.template',
    leftIcon: <MethodologyIcon />,
    canAccessPermission: METHODOLOGY,
    scope: READ
  },
  {
    key: 'fixed-points-categories',
    label: 'sidebar.fixedPointsList',
    leftIcon: <FixedPointsCategoriesIcon />,
    canAccessPermission: FIXED_POINTS,
    scope: READ,
    children: [
      {
        key: `fixed-points-categories/${FixedPointType.TRAP}`,
        label: 'fixedPointType.trap'
      },
      {
        key: `fixed-points-categories/${FixedPointType.PLUVIOMETER}`,
        label: 'fixedPointType.pluviometer'
      },
      {
        key: `fixed-points-categories/${FixedPointType.GENERIC}`,
        label: 'fixedPointType.generic'
      }
    ]
  },
  {
    key: 'canonicList',
    label: 'sidebar.canonicList',
    leftIcon: <CanonicListsIcon />,
    canAccessPermission: CANONICAL_CROP,
    scope: READ,
    children: [
      {
        key: 'crops',
        label: 'sidebar.crop'
      },
      {
        key: 'phenomenons',
        label: 'sidebar.phenomenon'
      },
      {
        key: 'phenomenon-categories',
        label: 'sidebar.phenomenonCategory'
      },
      {
        key: 'multiple-phenomenons-indicator',
        label: 'sidebar.multiplePhenomenonsIndicator'
      },
      {
        key: 'products',
        label: 'sidebar.product'
      },
      {
        key: 'productsets',
        label: 'sidebar.productset'
      },
      {
        key: 'vendors',
        label: 'sidebar.vendor'
      }
    ]
  },
  {
    key: 'organizations',
    label: 'sidebar.organizations',
    leftIcon: <OrganizationIcon />,
    canAccessPermission: ADMIN_DEVICES,
    scope: READ
  },
  {
    key: 'service-providers',
    label: 'sidebar.serviceProviders',
    leftIcon: <OrganizationIcon />,
    canAccessPermission: CANONICAL_CROP,
    scope: READ
  }
];

export default options;
