import { Company } from '../company/models';
import { Crop } from '../crop/models';
import {
  CustomIndicator,
  InspectionLayout,
  Methodology,
  OrderedCategory,
  OrderedComponent,
  OrderedExtraDimension,
  OrderedInspectionGroup,
  OrderedPhenomenon
} from '../methodology/models';
import { FeatureFlagEnum, UUID, Characteristic, Indicator, IndependentVariable, I18nMap } from '../models';
import { SeasonTree } from '../season/models';
import {
  BasicFormPayload,
  CategoryDeep,
  CharacteristicDeep,
  ComponentDeep,
  ExtraDimensionsDeep,
  IndicatorDeep,
  InspectionGroupDeep,
  InspectionLayoutDeep,
  MethodologyDeep,
  PhenomenonDeep
} from './models';
import { uuid } from '../../helpers';
import { getValueLanguage } from '../language/utils';
import { ExtraDimension } from '../extraDimensions/models';
import { defaultProductSet } from './mocks';

export const getMethodologyDeep = (
  methodology: Methodology,
  company?: Company,
  crop?: Crop,
  seasonTree?: SeasonTree[]
): MethodologyDeep => {
  const methodologyDeep = {
    id: methodology.id,
    name: methodology.name,
    description: methodology.description,
    isNew: false,
    company: {
      id: methodology.company_id,
      name: company ? company.name : ''
    },
    crop: {
      id: methodology.crop_id,
      name: { localized_strings: {} }
    },
    defaultMethodology: methodology.default_methodology || false,
    parentId: methodology.parent_id,
    seasonsTree: seasonTree ? getSeasonsTreeDeep(methodology, seasonTree) : {},
    modified_at: methodology.modified_at,
    deleted_at: methodology.deleted_at
  };

  methodologyDeep.crop = {
    id: methodology.crop_id,
    name: crop ? crop.name : { localized_strings: {} }
  };
  return methodologyDeep;
};

function getSeasonsTreeDeep(methodology: Methodology, seasonsTree: SeasonTree[]) {
  const seasonProperties = seasonsTree.map(seasonTree => seasonTree.seasonProperties).flat();
  const seasonAreas = seasonProperties.map(seasonProperty => seasonProperty.seasonAreas).flat();

  const seasons = seasonsTree.map(seasonTree => seasonTree.season);
  const properties = seasonProperties.map(property => property.property);
  const areas = seasonAreas.map(area => area.area);

  return {
    seasons: methodology.season_ids.map(seasonId => {
      return { id: seasonId, name: getNameById(seasons, seasonId) };
    }),
    seasonProperties: methodology.season_property_ids.map(seasonPropertyId => {
      return {
        id: seasonPropertyId,
        name: getNameById(properties, seasonPropertyId)
      };
    }),
    seasonAreas: methodology.season_area_ids.map(seasonAreaId => {
      return { id: seasonAreaId, name: getNameById(areas, seasonAreaId) };
    })
  };
}

export const getNameById = <T extends { id?: string; name?: string | I18nMap }>(list: T[], id: string): string | I18nMap | undefined => {
  const element = list.find(item => item.id === id);
  return element?.name ?? '';
};

export const getObjectById = <T extends { id?: string }>(list: T[], id: string): T | undefined => {
  return list?.find(item => item.id === id);
};

export const getInspectionLayoutDeep = (
  inspectionLayout: InspectionLayout,
  independentVariables: IndependentVariable[]
): InspectionLayoutDeep => {
  return {
    id: inspectionLayout.id || uuid(),
    independentVariables: independentVariables
      ? independentVariables.filter(v => inspectionLayout?.independent_variables?.map(v1 => v1.component_id).includes(v.id))
      : [],
    inspectionGroups: []
  };
};

export const getExtraDimensionsDeep = (
  orderedExtraDimensions: OrderedExtraDimension,
  extraDimensions?: ExtraDimension[]
): ExtraDimensionsDeep => {
  const extraDimensionResponse = extraDimensions?.find(ex => ex.id === orderedExtraDimensions.extra_dimension_id);
  return {
    id: extraDimensionResponse?.id ?? uuid(),
    name: extraDimensionResponse?.name,
    companyId: extraDimensionResponse?.company_id,
    description: extraDimensionResponse?.description,
    priority: orderedExtraDimensions.priority
  };
};

export const getInspectionGroupDeep = (inspectionGroup: OrderedInspectionGroup): InspectionGroupDeep => {
  return {
    id: inspectionGroup.id || uuid(),
    inspectionGroupId: inspectionGroup.component_id || uuid(),
    name: inspectionGroup.name,
    priority: inspectionGroup.priority,
    categories: [],
    extraDimensions: {
      id: uuid(),
      collapsed: false,
      components: []
    }
  };
};

export const getCategoryDeep = (category: OrderedCategory): CategoryDeep => {
  return {
    id: category.id || uuid(),
    categoryId: category.component_id,
    priority: category.priority,
    phenomenons: []
  };
};

export const getPhenomenonDeep = (phenomenon: OrderedPhenomenon): PhenomenonDeep => {
  return {
    id: phenomenon.id || uuid(),
    phenomenonId: phenomenon.component_id,
    priority: phenomenon.priority,
    components: []
  };
};

export const getComponentDeepWithProperties = (
  indicator?: IndicatorDeep,
  characteristic?: CharacteristicDeep,
  priority?: number
): ComponentDeep => {
  return {
    characteristic,
    indicator,
    priority
  };
};

export function isObjectCanonical(object) {
  return object.is_canonical !== undefined ? object.is_canonical : !object.parent_id && !object.company_id;
}

/**
 * Verify if the object in a Canonical Custom have a company_id and the object itself is canonical
 * @param object Any Entity of protector
 */
export function isObjectCanonicalCustom(object) {
  return object.company_id && object.is_canonical;
}

export function isObjectReferenceCanonical(object) {
  return object && isObjectCanonical(object) && !object.company_id;
}

export const getCharacteristicDeep = (ordered_component: OrderedComponent, phenomenonTreeComponent: Characteristic): CharacteristicDeep => {
  return {
    id: ordered_component.id ? ordered_component.id : uuid(),
    characteristicId: ordered_component.component_id,
    aliasesIds: ordered_component.aliases_ids || [],
    name: phenomenonTreeComponent.name,
    priority: ordered_component.priority,
    comesFromCanonical: isObjectCanonical(phenomenonTreeComponent)
  };
};

export const getSingleIndicatorDeep = (
  phenomenonTreeIndicator: Indicator,
  isDefault: boolean,
  customIndicator?: CustomIndicator,
  orderedComponent?: OrderedComponent
): IndicatorDeep => {
  return {
    id: orderedComponent?.id ?? uuid(),
    indicatorId: phenomenonTreeIndicator.id!,
    name: phenomenonTreeIndicator.name!,
    showOnInspectionLayout: !!orderedComponent,
    showOnTimeline: customIndicator
      ? !customIndicator.feature_flags?.map(featureFlag => featureFlag.label).includes(FeatureFlagEnum.NO_TIMELINE)
      : !phenomenonTreeIndicator?.feature_flags?.includes(FeatureFlagEnum.NO_TIMELINE),
    showOnHeatmap: customIndicator
      ? !customIndicator.feature_flags?.map(featureFlag => featureFlag.label).includes(FeatureFlagEnum.NO_HEATMAP)
      : !phenomenonTreeIndicator?.feature_flags?.includes(FeatureFlagEnum.NO_HEATMAP),
    diagnostics: customIndicator?.diagnostics ?? phenomenonTreeIndicator?.diagnostics,
    isDefault,
    priority: orderedComponent ? orderedComponent.priority : undefined,
    characteristicsIds: [...(phenomenonTreeIndicator?.expression?.characteristics ?? [])],
    phenomenonIds: phenomenonTreeIndicator.phenomenon_ids ? [...phenomenonTreeIndicator.phenomenon_ids] : [],
    comesFromCanonical: isObjectCanonical(phenomenonTreeIndicator)
  };
};

export const getCharacteristics = (ordered_components: OrderedComponent[]): OrderedComponent[] => {
  return ordered_components.filter(x => x.class_name.includes('Characteristic'));
};

export const getIndicators = (ordered_components: OrderedComponent[]): OrderedComponent[] => {
  return ordered_components.filter(x => x.class_name.includes('Indicator'));
};

export const getIndicatorsFromAnalyticContext = (customIndicators: CustomIndicator[], indicatorsIds: UUID[]): CustomIndicator[] => {
  return customIndicators.filter(indicator => indicatorsIds.includes(indicator.indicator_id));
};

export const getCharacteristicIds = (orderedComponents: OrderedComponent[]): UUID[] => {
  return getCharacteristics(orderedComponents).map(oc => oc.component_id);
};

export const getIndicatorIds = (orderedComponents: OrderedComponent[]): UUID[] => {
  return getIndicators(orderedComponents).map(oc => oc.component_id);
};

export function getMessageError(error) {
  return error.response?.data ? error.response.data.message : '';
}

export function filterByIndicator(c: ComponentDeep) {
  return Boolean(!c.characteristic && c.indicator);
}

export function filterByName(c: ComponentDeep, searchText: string) {
  return (
    (c.characteristic && getValueLanguage(c.characteristic.name).toLowerCase().includes(searchText.toLowerCase())) ||
    (c.indicator && getValueLanguage(c.indicator.name).toLowerCase().includes(searchText.toLowerCase()))
  );
}

export function getCharacteristicComponentById(
  deepComponents: ComponentDeep[],
  characteristicId: UUID,
  indicatorName?: string
): ComponentDeep {
  const characteristicComponent = deepComponents.find(c => c.characteristic?.characteristicId === characteristicId);
  if (characteristicComponent) {
    return characteristicComponent;
  }

  let messageError = '';
  if (indicatorName) {
    messageError = `Indicator ${indicatorName} references characteristic deleted in database. `;
  }
  messageError = `${messageError}Characteristic with id: ${characteristicId} not exists in deep components!`;

  throw new Error(messageError);
}

export function mapSeasonsTreeSeasonPropertiesSeasonAreasDeep(
  methodologyDeep: MethodologyDeep,
  seasonsTreeSelected?: string[],
  seasonsTree?: SeasonTree[]
) {
  const mutableMethodologyDeep = { ...methodologyDeep };
  if (!mutableMethodologyDeep?.seasonsTree || !seasonsTree) {
    return methodologyDeep;
  }

  mutableMethodologyDeep.seasonsTree.seasons = [];
  mutableMethodologyDeep.seasonsTree.seasonProperties = [];
  mutableMethodologyDeep.seasonsTree.seasonAreas = [];

  seasonsTree.forEach(seasonTree => {
    const seasons = seasonsTreeSelected?.filter(seasonTreeSelected => seasonTreeSelected === seasonTree.season.id);

    if (seasons?.length) {
      mutableMethodologyDeep.seasonsTree?.seasons?.push({
        id: seasonTree.season.id,
        name: seasonTree.season.name
      });
    }

    seasonTree.seasonProperties.forEach(seasonProperty => {
      const seasonProperties = seasonsTreeSelected?.filter(seasonTreeSelected => seasonTreeSelected === seasonProperty.id);
      if (seasonProperties?.length)
        mutableMethodologyDeep.seasonsTree?.seasonProperties?.push({
          id: seasonProperty.id,
          name: seasonProperty.property.name
        });
      seasonProperty.seasonAreas.forEach(seasonArea => {
        const seasonAreas = seasonsTreeSelected?.filter(seasonTreeSelected => seasonTreeSelected === seasonArea.id);
        if (seasonAreas?.length)
          mutableMethodologyDeep.seasonsTree?.seasonAreas?.push({
            id: seasonArea.id,
            name: seasonArea.area.name
          });
      });
    });
  });

  return mutableMethodologyDeep;
}
export const getSelectedPhenomenon = (methodologyDeep: MethodologyDeep, phenomenonId: string): PhenomenonDeep | undefined => {
  if (!methodologyDeep.inspectionLayout) return undefined;
  let selectedPhen: PhenomenonDeep | undefined;
  methodologyDeep.inspectionLayout.inspectionGroups.forEach(inspectionDeep => {
    if (inspectionDeep.categories)
      inspectionDeep.categories.forEach(categoryDeep => {
        if (categoryDeep.phenomenons)
          categoryDeep.phenomenons.forEach(phenDeep => {
            if (phenDeep.id === phenomenonId) {
              selectedPhen = phenDeep;
            }
          });
      });
  });

  return selectedPhen;
};

export const getAvailableComponentDeep = (allComponentDeep: ComponentDeep[], selectedPhenomenon: PhenomenonDeep): ComponentDeep[] => {
  let availableComponent: ComponentDeep[] = [];
  availableComponent = allComponentDeep.filter(compDeep => {
    if (compDeep.characteristic) return verifyCharacteristicIndicatorById(compDeep.characteristic.characteristicId, selectedPhenomenon);
    if (compDeep.indicator) return verifyCharacteristicIndicatorById(compDeep.indicator.indicatorId, selectedPhenomenon);
    return true;
  });
  return availableComponent;
};

export const verifyCharacteristicIndicatorById = (id: UUID | undefined, phenomenonDeep: PhenomenonDeep): boolean => {
  if (!phenomenonDeep || !phenomenonDeep.components || !id) {
    throw new Error('This phenomenon or components of phenomenon is null.');
  }
  let result = true;
  phenomenonDeep.components.forEach(compDeep => {
    if (compDeep.characteristic) {
      if (compDeep.characteristic.characteristicId === id) {
        result = false;
      }
    }
    if (compDeep.indicator) {
      if (compDeep.indicator.indicatorId === id) {
        result = false;
      }
    }
  });
  return result;
};

export const createNewMethodologyDeep = (): MethodologyDeep => {
  return {
    id: uuid(),
    isNew: true,
    name: {
      localized_strings: {
        en: ''
      }
    },
    description: {
      localized_strings: {
        en: ''
      }
    },
    seasonsTree: {},
    multiPhenomenaIndicators: [],
    analyticContextId: uuid()
  };
};

export const getPhenomenonsFromMethodologyDeep = (methodologyDeep: MethodologyDeep): any => {
  const result: any = [];
  if (methodologyDeep?.inspectionLayout) {
    const { inspectionLayout } = methodologyDeep;
    if (inspectionLayout) {
      inspectionLayout.inspectionGroups.forEach(ig => {
        result.push(ig.categories?.map(category => category.phenomenons).flat());
      });
    }
  }

  return result.flat();
};

export const getCategoryIds = (categories: CategoryDeep[]): string[] => {
  const idsArray: string[] = [];
  categories.forEach(obj => {
    if (obj.categoryId) idsArray.push(obj.categoryId);
  });
  return idsArray;
};

export const getIds = <T extends { id?: string }>(array?: T[]): string[] => {
  const idsArray: string[] = [];
  if (array) {
    array.forEach(obj => {
      if (obj.id) idsArray.push(obj.id);
    });
  }
  return idsArray;
};

export const clearEmptyCategoriesFromInspectionGroup = (inspectionGroupDeep: InspectionGroupDeep): InspectionGroupDeep => {
  return {
    ...inspectionGroupDeep,
    categories: inspectionGroupDeep.categories?.filter(cat => cat.phenomenons?.length)
  };
};

export const hasInspectionLayoutMoreThanNCharacteristics = (
  numberOfCharacteristics: number,
  inspectionLayout?: InspectionLayoutDeep
): InspectionGroupDeep | undefined => {
  let inspectionGroupWithMore;
  inspectionLayout?.inspectionGroups.forEach(inspectionGroup => {
    if (inspectionGroupWithMore) return;

    if (hasInspectionGroupMoreThanNCharacteristics(inspectionGroup, numberOfCharacteristics)) {
      inspectionGroupWithMore = inspectionGroup;
    }
  });

  return inspectionGroupWithMore;
};

export const hasInspectionGroupMoreThanNCharacteristics = (
  inspectionGroup: InspectionGroupDeep,
  numberOfCharacteristics: number
): boolean => {
  let totalCharacteristics = 0;
  inspectionGroup.categories?.forEach(category => {
    category.phenomenons?.forEach(phenomenon => {
      totalCharacteristics += phenomenon.components?.filter(component => component.characteristic).length ?? 0;
    });
  });
  return totalCharacteristics > numberOfCharacteristics;
};

export const updateBasicInformation = (basic: BasicFormPayload, methodology: MethodologyDeep) => {
  let updatedMethodology = { ...methodology };
  if (basic.description) {
    updatedMethodology.description = {
      localized_strings: {
        en: basic.description
      }
    };
  }
  if (basic.name) {
    updatedMethodology.name = {
      localized_strings: {
        en: basic.name
      }
    };
  }
  if (basic.seasons) {
    updatedMethodology = mapSeasonsTreeSeasonPropertiesSeasonAreasDeep(updatedMethodology, basic.seasons, basic.seasonsTree);
  }
  if (!basic.productSet) {
    updatedMethodology.productSet = defaultProductSet;
  }

  return updatedMethodology;
};
