import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CommandRequest } from '../../helpers/request/models';
import CONFIG from '../../settings';
import { PageableQuery, UUID } from '../models';
import { ExtraDimension, ExtraDimensionPage } from './models';
import { concatMap, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

export const EXTRA_DIMENSION_PAGE_SIZE = 50;
export const extraDimenstionsUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}`;
export const createExtraDimension = (request: CommandRequest<ExtraDimension>): AxiosObservable<any> => {
  const objToSave = {
    account: request.account,
    payload: {
      company_id: request.payload.company_id,
      id: request.payload.id,
      name: request.payload.name,
      description: request.payload.description,
      required: request.payload.required,
      values: request.payload.values
    }
  };
  return axios.post<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions`, objToSave);
};

export const updateExtraDimension = (request: CommandRequest<ExtraDimension>): AxiosObservable<any> => {
  const objToSave = {
    account: request.account,
    payload: {
      id: request.payload.id,
      company_id: request.payload.company_id,
      name: request.payload.name,
      description: request.payload.description,
      required: request.payload.required,
      values: request.payload.values
    }
  };
  return axios.put<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions`, objToSave);
};

export const loadExtraDimension = (id: UUID): AxiosObservable<any> => {
  return axios.get<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions/${id}`);
};

const loadExtraDimensionsByCompanyPage = (companyId: UUID, _query: PageableQuery) => {
  return axios
    .get<ExtraDimensionPage>(
      `${extraDimenstionsUrl}/api/v1/companies/${companyId}/extra-dimensions?page=${_query.pageable.page}&size=${_query.pageable.size}`
    )
    .pipe(map(m => m.data));
};

export const loadAllExtraDimensionsByCompany = (companyId: UUID) => {
  return loadExtraDimensionsByCompanyPage(companyId, { pageable: { page: 0, size: EXTRA_DIMENSION_PAGE_SIZE } }).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        loadExtraDimensionsByCompanyPage(companyId, {
          pageable: {
            page: p,
            size: EXTRA_DIMENSION_PAGE_SIZE
          }
        }).pipe(concatMap(extraDimensionsPage => of(extraDimensionsPage.content)))
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(extraDimensions => ({ data: { content: extraDimensions.flat() } }))
  );
};
