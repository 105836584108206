/* eslint-disable import/prefer-default-export */
import { useGetRBACAuthorityUser } from '../../querys/rbac/rbac.query';
import { RBACLevels, RBACActions, RBACPermissionTypesEnum } from '../../querys/rbac/rbac.query.model';

import { isEmpty } from 'lodash';
import asyncComponent from '../../helpers/AsyncFunc';

export const routesByPath = {
  dashboard: asyncComponent(() => import('../dashboard')),
  crops: asyncComponent(() => import('../Crop/crops')),
  'crops/by-company/:companyId': asyncComponent(() => import('../Crop/crops')),
  'crop-edit/:id': asyncComponent(() => import('../Crop/cropEdit')),
  'crop-edit/:id/by-company/:companyId': asyncComponent(() => import('../Crop/cropEdit')),
  'crop-edit': asyncComponent(() => import('../Crop/cropEdit')),
  ':companyId/characteristic-edit/:id?/by-phenomenon/:phenomenonId': asyncComponent(
    () => import('../Characteristic/characteristic.container')
  ),
  ':companyId/characteristic-edit/:id?': asyncComponent(() => import('../Characteristic/characteristic.container')),
  'characteristic-edit/:id?/by-phenomenon/:phenomenonId': asyncComponent(() => import('../Characteristic/characteristic.container')),
  'characteristic-edit/:id?': asyncComponent(() => import('../Characteristic/characteristic.container')),
  phenomenons: asyncComponent(() => import('../Phenomenon/phenomenons')),
  'phenomenon-edit/:id?/by-company/:companyId': asyncComponent(() => import('../Phenomenon/PhenomenonEdit/phenomenonEdit')),
  'phenomenon-edit/:id': asyncComponent(() => import('../Phenomenon/PhenomenonEdit/phenomenonEdit')),
  'phenomenon-edit': asyncComponent(() => import('../Phenomenon/PhenomenonEdit/phenomenonEdit')),
  'multiple-phenomenons-indicator': asyncComponent(() => import('../MultiplePhenomenonsIndicator/multiplePhenomenonsIndicator')),
  ':companyId/indicator-edit/:id?/by-phenomenon/:phenomenonId': asyncComponent(() => import('../Indicator/indicatorEdit')),
  ':companyId/indicator-edit/:id?': asyncComponent(() => import('../Indicator/indicatorEdit')),
  'indicator-edit/:id?/by-phenomenon/:phenomenonId': asyncComponent(() => import('../Indicator/indicatorEdit')),
  'indicator-edit/:id?': asyncComponent(() => import('../Indicator/indicatorEdit')),
  'fixed-point-category-edit/:context/:id?/by-company/:companyId': asyncComponent(
    () => import('../FixedPointCategories/fixedPointsCategoriesEdit')
  ),
  'fixed-point-category-edit/:context/:id?': asyncComponent(() => import('../FixedPointCategories/fixedPointsCategoriesEdit')),
  ':companyId?/independent-variable/:id?': asyncComponent(() => import('../IndependentVariable/independentVariableEdit')),
  'fixed-point-category-edit/:context': asyncComponent(() => import('../FixedPointCategories/fixedPointsCategoriesEdit')),
  'fixed-points-categories/:context': asyncComponent(() => import('../FixedPointCategories/fixedPointsCategories')),
  'fixed-points-categories/by-company/:companyId': asyncComponent(() => import('../FixedPointCategories/fixedPointsCategories')),
  'phenomenon-categories': asyncComponent(() => import('../PhenomenonCategory/phenomenonCategories')),
  'phenomenon-category-edit/:id': asyncComponent(() => import('../PhenomenonCategory/phenomenonCategoryEdit')),
  'phenomenon-category-edit': asyncComponent(() => import('../PhenomenonCategory/phenomenonCategoryEdit')),
  products: asyncComponent(() => import('../Product/products')),
  'product-edit/:id': asyncComponent(() => import('../Product/productEdit')),
  'product-edit': asyncComponent(() => import('../Product/productEdit')),
  productsets: asyncComponent(() => import('../ProductSet/productSets')),
  'product-set-edit/:id': asyncComponent(() => import('../ProductSet/productSetEdit')),
  'product-set-edit': asyncComponent(() => import('../ProductSet/productSetEdit')),
  vendors: asyncComponent(() => import('../Vendor/vendors')),
  'vendor-edit/:id': asyncComponent(() => import('../Vendor/vendorEdit')),
  'vendor-edit': asyncComponent(() => import('../Vendor/vendorEdit')),
  'new-methodology/:id': asyncComponent(() => import('../MethodologyContainer/methodologyContainer')),
  templates: asyncComponent(() => import('../MethodologyTemplate/methodologyTemplate')),
  'new-methodologies/by-company/:companyId': asyncComponent(() => import('../MethodologyList/methodologyList')),
  'users/rbac/:companyId': asyncComponent(() => import('../userV2RBAC/userV2RBAC')),
  organizations: asyncComponent(() => import('../Organizations/organizations')),
  'properties/by-company/:companyId': asyncComponent(() => import('../Properties/Properties')),
  measurements: asyncComponent(() => import('../DataMaintenance/dataMaintenance')),
  'extra-dimensions/by-company/:companyId/inspection-group/:inspectionGroupId': asyncComponent(
    () => import('../ExtraDimensionsEdit/extraDimensionsEdit.component')
  ),
  'extra-dimensions/:id/by-company/:companyId/by-methodology/:methodologyId/inspection-group/:inspectionGroupId': asyncComponent(
    () => import('../ExtraDimensionsEdit/extraDimensionsEdit.component')
  ),
  'service-providers': asyncComponent(() => import('../ServiceProviders/serviceProviders')),
  'custom-fields': asyncComponent(() => import('../CustomFields/custom-fields'))
};

export const rbacOperations = [
  {
    rbacLevels: RBACLevels.USER,
    rbacActions: RBACActions.METHODOLOGY,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacLevels: RBACLevels.USER,
    rbacActions: RBACActions.FIXED_POINTS,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacLevels: RBACLevels.USER,
    rbacActions: RBACActions.CANONICAL_CROP,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacLevels: RBACLevels.USER,
    rbacActions: RBACActions.ADMIN_DEVICES,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacLevels: RBACLevels.USER,
    rbacActions: RBACActions.CANONICAL_PHENOMENON,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacLevels: RBACLevels.USER,
    rbacActions: RBACActions.CANONICAL_CHARACTERISTICS,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CANONICAL_INDICATORS,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CANONICAL_PHENOMENON_CATEGORY,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CANONICAL_MULTI_PHENOMENON_INDICATOR,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CANONICAL_PRODUCT,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CANONICAL_PRODUCT_SET,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CANONICAL_VENDOR,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.ADMIN_ACCESS,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CUSTOM_CHARACTERISTICS,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CUSTOM_INDICATORS,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  },
  {
    rbacActions: RBACActions.CUSTOM_PHENOMENON,
    rbacLevels: RBACLevels.USER,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  }
];

export const usePagePermissions = () => {
  const pagePermission = useGetRBACAuthorityUser({
    rbacOperations
  });
  if (!pagePermission || isEmpty(pagePermission)) return [];

  return [
    {
      path: 'dashboard',
      canAccessPermission: true
    },
    {
      path: 'crops',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CROP]?.length
    },
    {
      path: 'crops/by-company/:companyId',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CROP]?.length
    },
    {
      path: 'crop-edit/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CROP]?.length
    },
    {
      path: 'crop-edit/:id/by-company/:companyId',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CROP]?.length
    },
    {
      path: 'crop-edit',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CROP]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: ':companyId/characteristic-edit/:id?/by-phenomenon/:phenomenonId',
      canAccessPermission: !!pagePermission?.[RBACActions.CUSTOM_CHARACTERISTICS]?.length
    },
    {
      path: ':companyId/characteristic-edit/:id?',
      canAccessPermission: !!pagePermission?.[RBACActions.CUSTOM_CHARACTERISTICS]?.length
    },
    {
      path: 'characteristic-edit/:id?/by-phenomenon/:phenomenonId',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CHARACTERISTICS]?.length
    },
    {
      path: 'characteristic-edit/:id?',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CHARACTERISTICS]?.length
    },
    {
      path: 'phenomenons',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PHENOMENON]?.length
    },
    {
      path: 'phenomenon-edit/:id?/by-company/:companyId',
      canAccessPermission: !!pagePermission?.[RBACActions.CUSTOM_PHENOMENON]?.length
    },
    {
      path: 'phenomenon-edit/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PHENOMENON]?.length
    },
    {
      path: 'phenomenon-edit',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PHENOMENON]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'multiple-phenomenons-indicator',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_MULTI_PHENOMENON_INDICATOR]?.length
    },
    {
      path: ':companyId/indicator-edit/:id?/by-phenomenon/:phenomenonId',
      canAccessPermission: !!pagePermission?.[RBACActions.CUSTOM_INDICATORS]?.length
    },
    {
      path: ':companyId/indicator-edit/:id?',
      canAccessPermission: !!pagePermission?.[RBACActions.CUSTOM_INDICATORS]?.length
    },
    {
      path: 'indicator-edit/:id?/by-phenomenon/:phenomenonId',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_INDICATORS]?.length
    },
    {
      path: 'indicator-edit/:id?',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_INDICATORS]?.length
    },
    {
      path: 'fixed-point-category-edit/:context/:id?/by-company/:companyId',
      canAccessPermission: !!pagePermission?.[RBACActions.FIXED_POINTS]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'fixed-point-category-edit/:context/:id?',
      canAccessPermission: !!pagePermission?.[RBACActions.FIXED_POINTS]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: ':companyId?/independent-variable/:id?',
      canAccessPermission: true
    },
    {
      path: 'fixed-point-category-edit/:context',
      canAccessPermission: !!pagePermission?.[RBACActions.FIXED_POINTS]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'fixed-points-categories/:context',
      canAccessPermission: !!pagePermission?.[RBACActions.FIXED_POINTS]?.length
    },
    {
      path: 'fixed-points-categories/by-company/:companyId',
      canAccessPermission: !!pagePermission?.[RBACActions.FIXED_POINTS]?.length
    },
    {
      path: 'phenomenon-categories',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PHENOMENON_CATEGORY]?.length
    },
    {
      path: 'phenomenon-category-edit/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PHENOMENON_CATEGORY]?.length
    },
    {
      path: 'phenomenon-category-edit',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PHENOMENON_CATEGORY]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'products',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PRODUCT]?.length
    },
    {
      path: 'product-edit/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PRODUCT]?.length
    },
    {
      path: 'product-edit',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PRODUCT]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'productsets',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PRODUCT_SET]?.length
    },
    {
      path: 'product-set-edit/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PRODUCT_SET]?.length
    },
    {
      path: 'product-set-edit',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_PRODUCT_SET]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'vendors',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_VENDOR]?.length
    },
    {
      path: 'vendor-edit/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_VENDOR]?.length
    },
    {
      path: 'vendor-edit',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_VENDOR]?.includes(RBACPermissionTypesEnum.WRITE)
    },
    {
      path: 'new-methodology/:id',
      canAccessPermission: !!pagePermission?.[RBACActions.METHODOLOGY]?.length
    },
    {
      path: 'templates',
      canAccessPermission: !!pagePermission?.[RBACActions.METHODOLOGY]?.length
    },
    {
      path: 'new-methodologies/by-company/:companyId',
      canAccessPermission: !!pagePermission?.[RBACActions.METHODOLOGY]?.length
    },
    {
      path: 'users/legacy/:companyId',
      canAccessPermission: true
    },
    {
      path: 'users/rbac/:companyId',
      canAccessPermission: true
    },
    {
      path: 'organizations',
      canAccessPermission: !!pagePermission?.[RBACActions.ADMIN_DEVICES]?.length
    },
    {
      path: 'properties/by-company/:companyId',
      canAccessPermission: true
    },
    {
      path: 'measurements',
      canAccessPermission: !!pagePermission?.[RBACActions.ADMIN_ACCESS]?.length
    },
    {
      path: 'extra-dimensions/by-company/:companyId/inspection-group/:inspectionGroupId',
      canAccessPermission: true
    },
    {
      path: 'extra-dimensions/:id/by-company/:companyId/by-methodology/:methodologyId/inspection-group/:inspectionGroupId',
      canAccessPermission: true
    },
    {
      path: 'service-providers',
      canAccessPermission: !!pagePermission?.[RBACActions.CANONICAL_CROP]?.length
    },
    {
      path: 'custom-fields',
      canAccessPermission: true
    }
  ];
};
