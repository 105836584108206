import { PageableQuery, UUID } from '../app/basicModels';
import { CloneCharacteristicsIndicatorsDTO, ClonedCharacteristicsIndicators, Phenomenon, PhenomenonPage, PhenomenonSingle } from './models';

const actions = {
  LOAD_PHENOMENONS: '[Phenomenon] Load Phenomenons',
  LOAD_PHENOMENONS_SUCCESS: '[Phenomenon] Load Phenomenons Success',
  LOAD_PHENOMENONS_FAILURE: '[Phenomenon] Load Phenomenons Failure',
  LOAD_PHENOMENONS_BY_METHODOLOGY: '[Phenomenon] Load Phenomenons by Methodology',
  LOAD_PHENOMENONS_BY_METHODOLOGY_SUCCESS: '[Phenomenon] Load Phenomenons by Methodology Success',
  LOAD_PHENOMENONS_BY_METHODOLOGY_FAILURE: '[Phenomenon] Load Phenomenons by Methodology Failure',
  SAVE_PHENOMENON: '[Phenomenon] Save Phenomenon',
  SAVE_PHENOMENON_SUCCESS: '[Phenomenon] Save Phenomenon Success',
  SAVE_PHENOMENON_FAILURE: '[Phenomenon] Save Phenomenon Failure',
  LOAD_PHENOMENON: '[Phenomenon] Load Phenomenon',
  LOAD_PHENOMENON_SUCCESS: '[Phenomenon] Load Phenomenon Success',
  LOAD_PHENOMENON_FAILURE: '[Phenomenon] Load Phenomenon Failure',
  DELETE_PHENOMENON: '[Phenomenon] Delete Phenomenon',
  DELETE_PHENOMENON_SUCCESS: '[Phenomenon] Delete Phenomenon Success',
  DELETE_PHENOMENON_FAILURE: '[Phenomenon] Delete Phenomenon Failure',
  LOAD_PHENOMENONS_TREE: '[Phenomenon] Load Phenomenons Tree',
  LOAD_PHENOMENONS_TREE_SUCCESS: '[Phenomenon] Load Phenomenons Tree Success',
  LOAD_PHENOMENONS_TREE_FAILURE: '[Phenomenon] Load Phenomenons Tree Failure',
  ENABLE_LOAD_PHENOMENONS_TREE: '[Phenomenon] Enable Load Phenomenons Tree',
  LOAD_PHENOMENONS_SIMPLE: '[Phenomenon] Load Phenomenons Simple',
  LOAD_PHENOMENONS_SIMPLE_SUCCESS: '[Phenomenon] Load Phenomenons Simple Success',
  LOAD_PHENOMENONS_SIMPLE_FAILURE: '[Phenomenon] Load Phenomenons Simple Failure',
  SELECT_PHENOMENON: '[Phenomenon] Select Phenomenon',
  TOGGLE_PHENOMENON_EDIT_MODAL: '[Phenomenon] Toggle phenomenon edit modal',
  UNSELECT_PHENOMENON: '[Phenomenon] Unselect Phenomenon',
  SET_LAST_COMPANY_ID_LOAD_PHENOMENONS_TREE: '[Phenomeno] Set last company id load Phenomenons Tree',
  UPDATE_PHENOMENON_TREE: '[Phenomenon] Update Phenomenon Tree',
  CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON: '[Phenomenon] Clone Characteristics Indicators to Phenomenon',
  CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON_SUCCESS: '[Phenomenon] Clone Characteristics Indicators to Phenomenon Success',
  CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON_FAILURE: '[Phenomenon] Clone Characteristics Indicators to Phenomenon Failure',

  loadPhenomenons: (pageableQuery: PageableQuery, showCanonicals: boolean = true, companyId: string | null | undefined = null) => ({
    type: actions.LOAD_PHENOMENONS,
    payload: {
      pageableQuery,
      companyId,
      showCanonicals
    }
  }),

  loadPhenomenonsSuccess: (phenomena: PhenomenonPage, companyId: UUID) => ({
    type: actions.LOAD_PHENOMENONS_SUCCESS,
    payload: { phenomena, companyId }
  }),

  loadPhenomenonsFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENONS_FAILURE,
    payload: error
  }),

  loadPhenomenonsByMethodology: (methodologyId: string) => ({
    type: actions.LOAD_PHENOMENONS_BY_METHODOLOGY,
    payload: methodologyId
  }),

  loadPhenomenonsByMethodologySuccess: (phenomena: PhenomenonSingle[]) => ({
    type: actions.LOAD_PHENOMENONS_BY_METHODOLOGY_SUCCESS,
    payload: phenomena
  }),

  loadPhenomenonsByMethodologyFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENONS_BY_METHODOLOGY_FAILURE,
    payload: error
  }),

  loadPhenomenonsSimple: (ids: UUID[]) => ({
    type: actions.LOAD_PHENOMENONS_SIMPLE,
    payload: ids
  }),

  loadPhenomenonsSimpleSuccess: phenomena => ({
    type: actions.LOAD_PHENOMENONS_SIMPLE_SUCCESS,
    payload: phenomena
  }),

  loadPhenomenonsSimpleFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENONS_SIMPLE_FAILURE,
    payload: error
  }),

  savePhenomenon: (phenomenon: Phenomenon, methodologyId?: UUID, orderedPhenomenonId?: UUID, orderedInspectionGroupId?: UUID) => ({
    type: actions.SAVE_PHENOMENON,
    payload: {
      phenomenon,
      methodologyId,
      orderedPhenomenonId,
      orderedInspectionGroupId
    }
  }),

  savePhenomenonSuccess: (phenomenon: Phenomenon) => ({
    type: actions.SAVE_PHENOMENON_SUCCESS,
    payload: phenomenon
  }),

  savePhenomenonFailure: (error: string) => ({
    type: actions.SAVE_PHENOMENON_FAILURE,
    payload: error
  }),

  loadPhenomenon: (id: UUID) => ({
    type: actions.LOAD_PHENOMENON,
    payload: id
  }),

  loadPhenomenonSuccess: (phenomenon: Phenomenon) => ({
    type: actions.LOAD_PHENOMENON_SUCCESS,
    payload: phenomenon
  }),

  loadPhenomenonFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENON_FAILURE,
    payload: error
  }),

  deletePhenomenon: (phenomenon: Phenomenon) => ({
    type: actions.DELETE_PHENOMENON,
    payload: phenomenon
  }),

  deletePhenomenonSuccess: (deleted: boolean) => ({
    type: actions.DELETE_PHENOMENON_SUCCESS,
    payload: deleted
  }),

  deletePhenomenonFailure: (error: string) => ({
    type: actions.DELETE_PHENOMENON_FAILURE,
    payload: error
  }),

  loadPhenomenonsTree: (pageableQuery: PageableQuery, companyId: UUID) => ({
    type: actions.LOAD_PHENOMENONS_TREE,
    payload: {
      pageableQuery,
      companyId
    }
  }),

  loadPhenomenonsTreeSuccess: (phenomenons: any) => ({
    type: actions.LOAD_PHENOMENONS_TREE_SUCCESS,
    payload: phenomenons
  }),

  loadPhenomenonsTreeFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENONS_TREE_FAILURE,
    payload: error
  }),

  selectPhenomenon: (phenomenon: Phenomenon) => ({
    type: actions.SELECT_PHENOMENON,
    payload: phenomenon
  }),

  togglePhenomenonEditModal: () => ({
    type: actions.TOGGLE_PHENOMENON_EDIT_MODAL
  }),

  unselectPhenomenon: () => ({
    type: actions.UNSELECT_PHENOMENON
  }),

  setLastCompanyIdLoadPhenomenonsTree: (companyId: UUID) => ({
    type: actions.SET_LAST_COMPANY_ID_LOAD_PHENOMENONS_TREE,
    payload: companyId
  }),

  setEnableLoadPhenomenonsTree: (enable: boolean) => ({
    type: actions.ENABLE_LOAD_PHENOMENONS_TREE,
    payload: enable
  }),

  updatePhenomenonTree: (phenomenonTree: Phenomenon[]) => ({
    type: actions.UPDATE_PHENOMENON_TREE,
    payload: phenomenonTree
  }),

  cloneCharacteristicsIndicatorsToPhenomenon: (itemsToClone: CloneCharacteristicsIndicatorsDTO[]) => ({
    type: actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON,
    payload: itemsToClone
  }),

  cloneCharacteristicsIndicatorsToPhenomenonSuccess: (clonedItems: ClonedCharacteristicsIndicators[], errors: string[]) => ({
    type: actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON_SUCCESS,
    payload: {
      clonedItems,
      errors
    }
  }),

  cloneCharacteristicsIndicatorsToPhenomenonFailure: (error: string[]) => ({
    type: actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON_FAILURE,
    payload: error
  })
};

export default actions;

export const PhenomenonActions = actions;
